import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useMemo } from 'react';

import * as state from '@/components/Analysis/state';
import { FocalPoint } from '@/types';

export const useFocalPointByAssembly = (
  assemblyName: string | undefined,
  focalPoint: FocalPoint | undefined
) => {
  const [focalPointsByAssemblyId, setFocalPointsByAssemblyId] = useRecoilState(
    state.focalPointsByAssemblyId
  );
  const cameraTarget = useRecoilValue(state.cameraTarget);

  const updatedFocalPoint = useMemo(() => {
    if (cameraTarget?.lookAt) {
      return {
        x: cameraTarget.lookAt[0],
        y: cameraTarget.lookAt[1],
        z: cameraTarget.lookAt[2],
      };
    }

    return assemblyName ? focalPoint : undefined;
  }, [assemblyName, cameraTarget?.lookAt, focalPoint]);

  useEffect(() => {
    if (!assemblyName || !updatedFocalPoint) {
      return;
    }

    setFocalPointsByAssemblyId((current) => {
      const newFocalPoints = { ...current };
      newFocalPoints[assemblyName] = {
        x: Number(updatedFocalPoint.x),
        y: Number(updatedFocalPoint.y),
        z: Number(updatedFocalPoint.z),
      };
      return newFocalPoints;
    });
  }, [assemblyName, setFocalPointsByAssemblyId, updatedFocalPoint]);

  return assemblyName ? focalPointsByAssemblyId[assemblyName] : undefined;
};
