import { Done as DoneIcon } from '@mui/icons-material';
import { Button, CircularProgress, SxProps } from '@mui/material';
import React, { useMemo } from 'react';

type Props = {
  loading: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
  disabled?: boolean;
  buttonVariant?: 'text' | 'contained' | 'outlined';
  loaderSize?: number;
  buttonStyles?: SxProps;
  loaderStyles?: SxProps;
  startIcon?: undefined | React.ReactNode;
  children?: React.ReactNode | undefined;
  success?: boolean;
  id?: string;
};

export const LoadingButton = ({
  loading,
  buttonVariant,
  buttonText,
  disabled,
  onClick,
  buttonStyles,
  loaderStyles,
  loaderSize,
  startIcon,
  children,
  success,
  id
}: Props) => {
  const buttonStylesComputed = useMemo(() => {
    return buttonStyles || { minWidth: '150px', maxWidth: '200px' };
  }, [buttonStyles]);

  const startIconComputed = useMemo(() => {
    return success ? <DoneIcon /> : startIcon;
  }, [startIcon, success]);

  return (
    <Button
      id={id}
      autoFocus
      disabled={disabled}
      color={success ? 'success' : 'primary'}
      variant={buttonVariant ?? 'contained'}
      onClick={onClick}
      sx={buttonStylesComputed}
      startIcon={startIconComputed}
      data-testId="loading-button"
    >
      {!success && loading && (
        <CircularProgress sx={loaderStyles || { color: '#fff' }} size={loaderSize ?? 24} />
      )}
      {!loading && <>{buttonText ?? children ?? ''}</>}
    </Button>
  );
};
