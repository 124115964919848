import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { LoadingButton } from '@/components/shared/LoadingButton';
import { TemplateSelector } from './TemplateSelector/TemplateSelector';
import { FilterActions } from './FilterActions/FilterActions';
import { AssemblyFilters } from './AssemblyFilters/AssemblyFilters';
import { DynamicFilters } from './DynamicFilters/DynamicFilters';
import {
  selectedAssemblyTemplateId,
  allAssemblyTemplates,
  assemblyTemplate as assemblyTemplateState,
  applyFilters as applyFiltersState,
} from '@/components/Analysis/state';
import { primary } from '@/theme/colors';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
import {
  TemplateTypeEnum,
  useAllAssemblyTemplatesForFilterSideBarQuery,
} from '@/__generated__/graphql';
import { useGetStructureId } from '@/hooks/useGetStructureId';

const FiltersSkeleton = () => {
  return (
    <>
      {Array.from({ length: 8 }, (_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width="100%"
          height={20}
          sx={{ marginTop: '32px' }}
        />
      ))}
    </>
  );
};

type FilterSideBarProps = {
  onClear?: () => void;
  showDynamicFilters?: boolean;
};

export const FilterSideBar = ({ onClear, showDynamicFilters = true }: FilterSideBarProps) => {
  const [value, setValue] = useState(0);
  const [assemblyTemplate, setAssemblyTemplate] = useRecoilState(assemblyTemplateState);
  const setAllAssemblyTemplates = useSetRecoilState(allAssemblyTemplates);
  const setSelectedTemplateId = useSetRecoilState(selectedAssemblyTemplateId);
  const [applyFilters, setApplyFilters] = useRecoilState(applyFiltersState);
  const { showSnackBar } = useSnackBarMessage({ variant: 'filled' });
  const [queryLoading, setQueryLoading] = useState<boolean>(applyFilters);
  const navbarHeight = useNavbarHeight();
  const structureId = useGetStructureId();

  const { data: templatesData } = useAllAssemblyTemplatesForFilterSideBarQuery({
    variables: {
      structureId,
      type: TemplateTypeEnum.Assembly,
    },
    skip: !structureId,
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    const assemblyTemplates = templatesData?.allTemplates;
    if (assemblyTemplates) {
      setAllAssemblyTemplates(templatesData.allTemplates);
      const selectedTemplate = assemblyTemplates[0];
      if (!selectedTemplate) return;
      setSelectedTemplateId(selectedTemplate.id);
      setAssemblyTemplate(selectedTemplate);
    }
  }, [
    templatesData?.allTemplates,
    setAssemblyTemplate,
    setSelectedTemplateId,
    setAllAssemblyTemplates,
  ]);

  const filtersLoaded = useMemo(() => {
    return assemblyTemplate?.fields;
  }, [assemblyTemplate]);

  const handleClick = useCallback(() => {
    setApplyFilters(true);
    setQueryLoading(true);
    showSnackBar('Query has been applied successfully', 'success');
  }, [setApplyFilters, showSnackBar]);

  useEffect(() => {
    if (!applyFilters) {
      setQueryLoading(false);
    }
  }, [applyFilters]);

  return (
    <Stack data-testid="filter-sidebar" sx={{ height: `calc(100vh - ${navbarHeight + 52}px)` }}>
      <Stack>
        <FilterActions onClear={onClear} />
      </Stack>
      {!filtersLoaded && <FiltersSkeleton />}
      {filtersLoaded && (
        <Stack
          sx={{
            overflowY: 'auto',

            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <TemplateSelector value={value} setValue={setValue} />
          {showDynamicFilters && <DynamicFilters />}
          <AssemblyFilters value={value} />
        </Stack>
      )}
      {filtersLoaded && (
        <Stack>
          <LoadingButton
            loading={queryLoading}
            disabled={queryLoading}
            onClick={handleClick}
            buttonVariant="contained"
            buttonStyles={{
              background: primary.darkBlue,
              height: '42px',
              borderRadius: 0.5,
              fontSize: '1.5rem',
              fontWeight: 500,
              mt: 2,
              mb: 2,
              width: '100%',
              p: 1,
              textTransform: 'none',
              '@media (max-width: 1728px)': {
                height: '32px',
                mt: 1,
                mb: 1,
              },
            }}
            buttonText="Apply Query"
          />
        </Stack>
      )}
    </Stack>
  );
};
