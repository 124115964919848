/**
 * Determines if the defect named "Clean" should be disabled.
 * "Clean" is disabled by default as it is not needed for corrosion related defects.
 * The non selected check will do the job of clean category
 *
 * @param {string} defectName - The name of the defect.
 * @returns {boolean} - Returns true if the defect should be disabled, otherwise false.
 */
export const shouldDisableCleanDefect = (defectName: string) =>
  defectName.toLowerCase() === 'clean';
