import { useRecoilValue } from 'recoil';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { selectedPointOfInterestId as selectedPointOfInterestIdState } from '../../../state';
import { PAINT_BLOCK_LABEL } from '@/constants';
import { AbyssIcon } from '@/assets/icons/AbyssIcon';
import { textButtonStyle } from '@/theme';
import { abyssColors } from '@/theme/colors';
import { useUpdatePaintRegionId } from '../hooks/useUpdatePaintRegionId';

type PaintRegionFieldProps = {
  label: string;
  type?: string;
};

export const PaintRegionField = ({ label, type }: PaintRegionFieldProps) => {
  const selectedPointOfInterestId = useRecoilValue(selectedPointOfInterestIdState);

  const { isRetrying, handleAddPaintRegionId } = useUpdatePaintRegionId(
    [selectedPointOfInterestId ?? ''],
    type
  );

  return (
    <Stack direction="row" justifyContent="space-between" key={label}>
      <Typography sx={{ fontSize: '1.2rem', fontWeight: 500, color: abyssColors.primary[400] }}>
        {PAINT_BLOCK_LABEL}
      </Typography>
      <Button
        key={label}
        startIcon={
          isRetrying ? (
            <CircularProgress size={10} />
          ) : (
            <AbyssIcon iconName="retry" style={{ width: '1.75rem', height: '1.75rem' }} />
          )
        }
        variant="outlined"
        size="small"
        sx={{
          ...textButtonStyle,
          height: 26,
        }}
        onClick={handleAddPaintRegionId}
      >
        {isRetrying ? 'Retrying...' : 'Try Again'}
      </Button>
    </Stack>
  );
};
