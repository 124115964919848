import { useSetRecoilState } from 'recoil';
import { PointOfInterest, PointOfInterestTemplate } from '@/__generated__/graphql';
import { PoiFormState } from '@/types';
import * as poiState from '../../../state';
import { BLISTER_TYPE, PIT_TYPE } from '@/constants';

type Props = {
  pointOfInterest?: PointOfInterest;
  formState: PoiFormState;
  template?: PointOfInterestTemplate;
};

export const useTriggerPoiEditing = () => {
  const setEditPointOfInterest = useSetRecoilState(poiState.editPointOfInterest);
  const setBlisterToAdd = useSetRecoilState(poiState.blisterToAdd);
  const setBlisterPolys = useSetRecoilState(poiState.blisterPolygons);

  const triggerEdit = ({ formState, pointOfInterest, template }: Props) => {
    setEditPointOfInterest((previous) => ({
      ...previous,
      pointOfInterest,
      formState,
      state: 'WaitingForDetail',
      template,
    }));

    if (pointOfInterest?.type === BLISTER_TYPE || pointOfInterest?.type === PIT_TYPE) {
      setBlisterToAdd({ state: 'UpdatingBlister' });
      setBlisterPolys((previous) => {
        if (!previous) return undefined;
        return previous.map((item) =>
          item.id && pointOfInterest.id && item.id.startsWith(pointOfInterest.id)
            ? { ...item, isVisible: true }
            : item
        );
      });
    }
  };

  const cancelEditing = () => {
    setBlisterToAdd(undefined);
    setEditPointOfInterest((previous) => ({
      ...previous,
      pointOfInterest: undefined,
      formState: undefined,
      state: 'Inactive',
    }));
  };

  return { triggerEdit, cancelEditing };
};
