import { PropsWithChildren, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useUser } from '@auth0/nextjs-auth0';
import { useRecoilState } from 'recoil';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { auth0TokenState } from '@/state';
import { DefaultLayout } from '@/layouts';
import { initializePendo } from '@/utils/pendo';
import { PageLoader } from './shared/PageLoader';
import { ErrorScreen } from './shared/ErrorScreen';

const GoToLogin = () => {
  const router = useRouter();
  useEffect(() => {
    router.push('/api/auth/login');
  }, [router]);

  return <></>;
};
export const AuthGuard = ({ children }: PropsWithChildren<{}>) => {
  const { user, error, isLoading } = useUser();
  const [authCompleted, setAuthCompleted] = useState<boolean>(false);
  const [accessToken, setToken] = useRecoilState(auth0TokenState);
  const router = useRouter();
  useEffect(() => {
    if (user?.email && process.env.NEXT_PUBLIC_PENDO_PUBLIC_KEY) {
      initializePendo({
        visitor: {
          id: user.email,
        },
        account: {
          id: 'abyss-fabric-v2',
        },
      });
    }
  }, [user?.email]);

  useEffect(() => {
    if (isLoading) return;
    if (accessToken) {
      setAuthCompleted(true);
      return;
    }

    // fetch session access token and set recoil token state
    fetch('/api/auth/token').then((response) => {
      try {
        response
          .json()
          .then(({ token }) => {
            setToken(token);
          })
          .then(() => {
            setAuthCompleted(true);
          });
      } catch {
        // cater for possible exception
      }
    });
  }, [isLoading, accessToken, setToken]);

  if (isLoading || !authCompleted) {
    return (
      <DefaultLayout>
        <Box sx={{ position: 'relative', top: '25rem' }}>
          <PageLoader />
        </Box>
      </DefaultLayout>
    );
  }

  // Exclude error route from Auth Guard
  if (router.pathname === '/error') {
    return <ErrorScreen />;
  }

  if (error) {
    return (
      <DefaultLayout>
        <Typography sx={{ mt: 10 }}>Error Logging in</Typography>
      </DefaultLayout>
    );
  }

  if (authCompleted && !accessToken) {
    return <GoToLogin />;
  }

  return <>{children}</>;
};
