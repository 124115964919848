import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import Alert from '@mui/material/Alert';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import * as state from '@/components/Analysis/state';
import {
  PointOfInterestDocument,
  PointOfInterestsDocument,
  useUpdatePaintRegionIdMutation,
} from '@/__generated__/graphql';

export type UseUpdatePaintRegionIdHookType = {
  isRetrying: boolean;
  handleAddPaintRegionId: () => void;
};

export const useUpdatePaintRegionId = (
  pointOfInterestIds: string[],
  type?: string
): UseUpdatePaintRegionIdHookType => {
  const structureId = useGetStructureId();
  const setSnackbarMessage = useSetRecoilState(state.snackbarMessage);
  const setApplyFilters = useSetRecoilState(state.applyFilters);
  const [updatePaintRegionId, { loading: isRetrying }] = useUpdatePaintRegionIdMutation({
    refetchQueries: [PointOfInterestDocument, PointOfInterestsDocument],
    onCompleted: (response) => {
      setApplyFilters(true);
      if (response?.updatePaintRegionId) {
        setSnackbarMessage({
          shouldShow: true,
          content: (
            <Alert severity="success" variant="filled">
              {`The Paint Block has been successfully added to the ${pointOfInterestIds.length > 1 ? 'POIs' : 'POI'}`}
            </Alert>
          ),
        });
      }
    },
    onError: (error) => {
      setSnackbarMessage({
        shouldShow: true,
        content: (
          <Alert severity="error" variant="filled">
            {error.message}
          </Alert>
        ),
      });
      console.error(error);
    },
  });

  const handleAddPaintRegionId = useCallback(() => {
    if (pointOfInterestIds?.length > 0) {
      updatePaintRegionId({
        variables: {
          input: {
            structureId,
            poiIds: pointOfInterestIds,
            type: type,
          },
        },
      });
    }
  }, [pointOfInterestIds, structureId, type, updatePaintRegionId]);

  return { isRetrying, handleAddPaintRegionId };
};
