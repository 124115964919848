import { Box, Button, DialogActions, Typography } from '@mui/material';
import { alpha, styled as muiStyled } from '@mui/material/styles';
import { abyssColors } from '@/theme/colors';

type ButtonProps = {
  contained?: boolean;
};

export const PoiFormHeading = muiStyled(Typography)(() => ({
  fontSize: '1.6rem',
  lineHeight: 2.4,
  fontWeight: 700,
}));

export const PoiFormSubHeading = muiStyled(Typography)(() => ({
  fontSize: '1.6rem',
  lineHeight: 2.4,
  fontWeight: 500,
}));

export const ErrorChip = muiStyled(Box)(() => ({
  backgroundColor: alpha(abyssColors.error[50], 0.5),
  borderRadius: '4px',
  height: '22px',
  padding: '0px 10px',
  color: abyssColors.error[600],
  fontSize: '1.2rem',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '0.15px',
  display: 'flex',
  alignItems: 'center',
}));

export const DialogHeading = muiStyled(Typography)(() => ({
  fontSize: '2rem',
  lineHeight: 'normal',
  fontWeight: 500,
  color: abyssColors.primary[700],
}));

export const DialogText = muiStyled(Typography)(() => ({
  fontSize: '1.4rem',
  lineHeight: '2rem',
  color: abyssColors.primary[700],
}));

export const ActionButton = muiStyled(Button)(({ contained }: ButtonProps) => ({
  padding: '0.6rem 1.6rem',
  height: '3.6rem',
  borderRadius: '0.4rem',
  fontSize: '1.4rem',
  background: contained ? abyssColors.primary[500] : abyssColors.neutral[50],
  color: contained ? abyssColors.neutral[50] : abyssColors.primary[500],
  boxShadow: 'none',
  textTransform: 'none',
  width: 'fit-content',
  lineHeight: 'normal',
  marginLeft: '0px !important',
  gap: '0.6rem',
  '&:disabled': {
    background: contained ? abyssColors.neutral[300] : abyssColors.neutral[50],
    color: contained ? abyssColors.neutral[50] : abyssColors.primary[300],
  },
  '&:hover': {
    background: contained ? abyssColors.primary[400] : abyssColors.neutral[50],
  },
}));

export const StyledDialogActions = muiStyled(DialogActions)(() => ({
  position: 'absolute',
  width: '100%',
  bottom: '0px',
  right: '0px',
  padding: '16px !important',
  paddingRight: '25px !important',
}));
