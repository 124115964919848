import React, { useMemo, useState } from 'react';
import { GenericModal } from '@/components/shared/GenericModal';
import { dialogOverlay } from '@/components/UserManagement/styles';
import { Button, DialogContent } from '@mui/material';
import { UncertainFieldHook } from '../hooks/useUncertainField';
import { DropDownField } from '@/components/shared/CustomField';
import { StyledDialogActions } from './styles';

type Props = {
  dialog?: {
    open: boolean;
    handleClose: () => void;
    handleClearMeasurement: () => void;
  };
  uncertainField: UncertainFieldHook;
  disabled?: boolean;
};

export const UncertainReasonField = ({ dialog, uncertainField, disabled }: Props) => {
  const [reason, setReason] = useState(uncertainField.fieldValue);
  const { field, handleUpdateReason } = uncertainField;

  const fieldComponent = useMemo(() => {
    if (!field) return <></>;
    const handleValueChange = (value: string) => {
      setReason(value);
      if (!dialog) handleUpdateReason(value);
    };
    const { options, id, name } = field;
    return (
      <DropDownField
        mode="Edit"
        id={id}
        title={name}
        sx={{ mt: 2 }}
        value={reason}
        options={options}
        disableClearable
        disabled={disabled}
        onFieldValueChange={(value) => handleValueChange(value)}
      />
    );
  }, [field, disabled, reason, dialog, handleUpdateReason]);

  const dialogComponent = useMemo(() => {
    if (!dialog) return <></>;
    const { open, handleClose, handleClearMeasurement } = dialog;
    const handleConfirm = () => {
      handleUpdateReason(reason);
      handleClearMeasurement();
      handleClose();
    };
    return (
      <GenericModal
        open={open}
        handleClose={handleClose}
        title="Mark it as uncertain?"
        crossIconStyle={{ backgroundColor: 'transparent', color: 'primary' }}
        sx={{ ...dialogOverlay, left: '40% !important', top: '20%', width: 472, height: 270 }}
      >
        <DialogContent>{fieldComponent}</DialogContent>
        <StyledDialogActions>
          <Button onClick={handleClose} variant="outlined" sx={{ mr: 1, height: '36px' }}>
            Cancel
          </Button>
          <Button variant="contained" disabled={!reason} onClick={handleConfirm}>
            Confirm
          </Button>
        </StyledDialogActions>
      </GenericModal>
    );
  }, [dialog, fieldComponent, reason, handleUpdateReason]);

  if (!field) return <></>;

  return <>{dialog ? dialogComponent : fieldComponent}</>;
};
