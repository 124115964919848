import * as state from '@/components/Analysis/state';
import { UpgradePromptBackground, UpgradePromptType } from '@/types';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
import {
  BoldDetail,
  DetailText,
  DetailsPanel,
  FullScreenPrompt,
  GraphicsPanel,
  Heading,
  Highlight,
  Overlay,
  Paragraph,
  Pill,
  PromptBackground,
  PromptCard,
  PromptDialog,
  SupportButton,
} from './styles';
import { Stack, Typography } from '@mui/material';
import { GenericAccordion } from '@/components/shared/GenericAccordion';
import { AbyssIcon } from '@/assets/icons/AbyssIcon';
import { abyssColors } from '@/theme/colors';
import { SALES_CONTACT_EMAIL } from '@/constants';

type UpgradePromptProps = {
  feature: string;
  featureTitle: string;
  subFeature?: string;
  children?: React.ReactNode;
  background?: UpgradePromptBackground;
  paragraphs?: string[];
};

const LicensePill = () => {
  return (
    <Pill marginLeft="0.8rem">
      <AbyssIcon
        iconName="crown"
        style={{ width: '1.2rem', height: '1.2rem', marginTop: '-0.1rem' }}
      />
      <Typography
        fontSize="1.2rem"
        lineHeight="normal"
        fontWeight={500}
        color={abyssColors.warning[600]}
      >
        License
      </Typography>
    </Pill>
  );
};

export const UpgradePrompt = ({
  feature,
  children,
  featureTitle,
  paragraphs = [],
  subFeature = 'ALL',
  background = UpgradePromptBackground.None,
}: UpgradePromptProps) => {
  const structureFeatures = useRecoilValue(state.structureFeatures);
  const [showPrompt, setShowPrompt] = useState<Boolean>(false);
  const [promptType, setPromptType] = useState<string | undefined>();
  const navbarHeight = useNavbarHeight();

  const sendEmailToSales = `mailto:${SALES_CONTACT_EMAIL}`;

  useEffect(() => {
    if (
      structureFeatures?.features?.[feature] &&
      Array.isArray(structureFeatures?.features?.[feature])
    ) {
      const subFeatures = structureFeatures.features?.[feature];
      const selectedSubfeature = subFeatures.find(
        (item: { flag: string }) => item?.flag === subFeature
      );
      setShowPrompt(selectedSubfeature?.paywall ?? false);
      setPromptType(selectedSubfeature?.paywallType ?? UpgradePromptType.FULLSCREEN_POPUP);
    }
  }, [feature, structureFeatures?.features, subFeature]);

  if (!showPrompt) return <>{children}</>;
  if (promptType === UpgradePromptType.FULLSCREEN_POPUP && showPrompt)
    return (
      <FullScreenPrompt navbarHeight={navbarHeight}>
        {background !== UpgradePromptBackground.None && (
          <PromptBackground background={background} />
        )}
        <Overlay />
        <PromptDialog>
          <GraphicsPanel>
            <img src="/assets/abyss_logo_white.png" id="logo" />
            <img src="/assets/promotion-sample-viewer.png" id="promotionImage1" />
            <img src="/assets/promotion-sample-insights.png" id="promotionImage2" />
          </GraphicsPanel>
          <DetailsPanel>
            <Heading>
              The <Highlight>{featureTitle}</Highlight> feature requires a license upgrade to
              access.
            </Heading>
            <Stack gap="2.4rem" marginY="3.2rem">
              {paragraphs.map((paragraph) => (
                <Paragraph>{paragraph}</Paragraph>
              ))}
            </Stack>
            <a href={sendEmailToSales}>
              <SupportButton contained>Contact Sales</SupportButton>
            </a>
          </DetailsPanel>
        </PromptDialog>
      </FullScreenPrompt>
    );
  if (promptType === UpgradePromptType.INLINE_DROPDOWN)
    return (
      <Stack>
        <GenericAccordion
          summary={featureTitle}
          summaryStyles={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}
          customSummaryChip={<LicensePill />}
        >
          <PromptCard>
            <AbyssIcon iconName="crown" style={{ width: '2.4rem', height: '2.4rem' }} />
            <DetailText>
              <BoldDetail>To access this feature, please upgrade your current license. </BoldDetail>
              {paragraphs.join(' ')}
            </DetailText>
            <a href={sendEmailToSales}>
              <SupportButton contained compact>
                Contact Sales
              </SupportButton>
            </a>
          </PromptCard>
        </GenericAccordion>
      </Stack>
    );
};
