import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Link, Stack, SxProps, Typography } from '@mui/material';
import { useCallback } from 'react';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { FieldViewer } from '../../FieldViewer';
import { useTriggerPoiEditing } from '../../hooks';
import { PointOfInterest, PointOfInterestTemplate } from '@/__generated__/graphql';
import { GenericAccordion } from '@/components/shared/GenericAccordion';
import { UpgradePrompt } from '@/components/Analysis/shared/UpgradePrompt';
import { CustomTextField } from '@/components/shared/CustomField';
import { NOT_APPLICABLE_LABEL } from '@/constants';
import { abyssColors } from '@/theme/colors';

type ShareProps = {
  pointOfInterest: PointOfInterest;
  template: PointOfInterestTemplate;
};

type AitUserVerifiedPoiProps = {
  fields: Array<{ label: string; value: string }>;
  disableEdit?: boolean;
} & ShareProps;

export const AitUserVerifiedPoiView = ({
  fields,
  pointOfInterest,
  template,
  disableEdit,
}: AitUserVerifiedPoiProps) => {
  const { triggerEdit } = useTriggerPoiEditing();

  return (
    <Box sx={{ px: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
        <Typography variant="h6">Pre Inspection Data</Typography>
        {disableEdit || (
          <IconButton
            onClick={() => {
              triggerEdit({
                formState: 'Update-Template',
                pointOfInterest,
                template,
              });
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      <FieldViewer fields={fields} />
    </Box>
  );
};

type InspectorUnverifiedPoiProps = {} & ShareProps;

const InspectorUnverifiedPoiView = ({ pointOfInterest, template }: InspectorUnverifiedPoiProps) => {
  const { triggerEdit } = useTriggerPoiEditing();

  const onClick = useCallback(() => {
    triggerEdit({
      formState: 'Verify',
      pointOfInterest,
      template,
    });
  }, [pointOfInterest, template, triggerEdit]);

  return (
    <>
      <Typography>Data awaits verification</Typography>
      <Link component="button" onClick={onClick} mx={1}>
        Click here
      </Link>
      <Typography>to verify the data</Typography>
    </>
  );
};

type InspectorVerifiedPoiViewProps = {
  pointOfInterest: PointOfInterest;
  template: PointOfInterestTemplate;
  fields: Array<{ label: string; value: string }>;
  disableEdit?: boolean;
};
export const InspectorVerifiedPoiView = ({
  pointOfInterest,
  template,
  fields,
  disableEdit,
}: InspectorVerifiedPoiViewProps) => {
  const { triggerEdit } = useTriggerPoiEditing();
  return (
    <Box sx={{ px: 3 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
        <Typography fontSize="1.6rem" fontWeight={800}>
          Post Inspection Data
        </Typography>
        {disableEdit || (
          <IconButton
            onClick={() => {
              triggerEdit({
                formState: 'Verify',
                pointOfInterest,
                template,
              });
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      <FieldViewer fields={fields} sx={{ pb: 2 }} />
      <CustomTextField
        key="verified-by"
        mode="ReadOnly"
        id="verified-by"
        title="Verified By"
        value={pointOfInterest.verifiedByUser?.email ?? NOT_APPLICABLE_LABEL}
        sx={{ color: abyssColors.primary[400] }}
      />
    </Box>
  );
};

type GenericAccordionProps = {
  fields: Array<{ label: string; value: string }>;
  accordianProps?: SxProps;
  template: PointOfInterestTemplate;
  pointOfInterest: PointOfInterest;
  canVerifyData: boolean;
};

type VerifiedFieldsAccordianProps = {
  canVerifyData: boolean;
} & GenericAccordionProps;
const VerifiedFieldsAccordian = ({
  fields,
  accordianProps,
  pointOfInterest,
  template,
  canVerifyData,
}: VerifiedFieldsAccordianProps) => (
  <GenericAccordion
    summary="Post Inspection Data"
    summaryStyles={{ fontWeight: 500 }}
    openByDefault
    {...accordianProps}
  >
    <Stack alignItems="center">
      {(() => {
        if (pointOfInterest.isVerified) {
          return <FieldViewer sx={{ width: '100%' }} fields={fields} />;
        } else if (canVerifyData) {
          return (
            <Box sx={{ width: '70%', textAlign: 'center' }}>
              <InspectorUnverifiedPoiView pointOfInterest={pointOfInterest} template={template} />
            </Box>
          );
        } else {
          return (
            <Stack alignItems="center" my={2}>
              <FindInPageIcon fontSize="large" />
              <Typography fontWeight={400}>Data awaits verification from API Inspector</Typography>
            </Stack>
          );
        }
      })()}
    </Stack>
  </GenericAccordion>
);

export const InspectorVerifiedFieldsAccordian = ({
  fields,
  accordianProps,
  pointOfInterest,
  template,
  canVerifyData,
}: GenericAccordionProps) => (
  <UpgradePrompt
    feature="inspectionServices"
    subFeature="VERIFICATION"
    featureTitle="Field Verification Data"
    paragraphs={[
      'Upgrading will allow you to verify your POIs and complete your workflow in Abyss Fabric without limitations.',
    ]}
  >
    <VerifiedFieldsAccordian
      fields={fields}
      accordianProps={accordianProps}
      template={template}
      pointOfInterest={pointOfInterest}
      canVerifyData={canVerifyData}
    />
  </UpgradePrompt>
);

type InspectorBlisterEditIconButtonProps = {
  pointOfInterest: PointOfInterest;
  template: PointOfInterestTemplate;
};

export const InspectorBlisterEditIconButton = ({
  pointOfInterest,
  template,
}: InspectorBlisterEditIconButtonProps) => {
  const { triggerEdit } = useTriggerPoiEditing();

  return (
    <IconButton
      onClick={(event) => {
        event.stopPropagation();
        triggerEdit({
          formState: 'Update-Template',
          pointOfInterest,
          template,
        });
      }}
    >
      <EditIcon sx={{ ml: 'auto' }} />
    </IconButton>
  );
};
