import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { Blister, Maybe, PointOfInterest, TemplateField } from '@/__generated__/graphql';

export type TemplateDataHookKeys = { [key: string]: string };

type Action =
  | { type: 'UPDATE_FIELD'; payload: { key: string; value: string } }
  | { type: 'REMOVE_FIELD'; payload: { key: string; value: '' } }
  | { type: 'ADD_FIELD'; payload: { key: string; value: string } }
  | { type: 'RESET_STATE'; payload: TemplateDataHookKeys };

const reducer = (state: TemplateDataHookKeys, action: Action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'REMOVE_FIELD':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'ADD_FIELD':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'RESET_STATE':
      return action.payload;
    default:
      return state;
  }
};

export type UseTemplateFieldsHookType = {
  type: string;
  blister: Maybe<Blister> | undefined;
  state: TemplateDataHookKeys;
  handleChange: (key: string, value: string) => void;
  handleRemove: (key: string) => void;
  handleAdd: (key: string, value: string) => void;
};

type Props = {
  templateFields: TemplateField[] | undefined;
  selectedPoiInfo: PointOfInterest | undefined;
  fieldType?: 'value' | 'verifiedValue';
};

export const useTemplateFields = ({
  templateFields,
  selectedPoiInfo,
  fieldType = 'value',
}: Props): UseTemplateFieldsHookType => {
  const isUserInteracting = useRef(false);
  const initialState = useMemo(
    () =>
      (templateFields || []).reduce((accumulator, templateField) => {
        const templateData = selectedPoiInfo?.templateData?.find(
          (td) => td.id === templateField.id
        );

        return {
          ...accumulator,
          [templateField.id]: templateData?.[fieldType] ?? templateData?.value ?? '',
        };
      }, {} as TemplateDataHookKeys),
    [fieldType, selectedPoiInfo?.templateData, templateFields]
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const previousTemplateFields = Object.keys(state);
    const newTemplateFields = Object.keys(initialState);

    if (JSON.stringify(previousTemplateFields) !== JSON.stringify(newTemplateFields))
      isUserInteracting.current = false;

    if (JSON.stringify(initialState) !== JSON.stringify(state) && !isUserInteracting.current)
      dispatch({ type: 'RESET_STATE', payload: state });
  }, [initialState, state, templateFields]);

  const handleChange = useCallback((key: string, value: string) => {
    isUserInteracting.current = true;
    dispatch({ type: 'UPDATE_FIELD', payload: { key, value } });
  }, []);

  const handleRemove = useCallback((key: string) => {
    isUserInteracting.current = true;
    dispatch({ type: 'REMOVE_FIELD', payload: { key, value: '' } });
  }, []);

  const handleAdd = useCallback((key: string, value: string) => {
    isUserInteracting.current = true;
    dispatch({ type: 'ADD_FIELD', payload: { key, value } });
  }, []);

  return {
    state,
    handleChange,
    handleRemove,
    handleAdd,
    type: selectedPoiInfo?.type ?? '',
    blister: selectedPoiInfo?.blister,
  };
};
