import { PointOfInterest } from '@/__generated__/graphql';
import { abyssColors } from '@/theme/colors';
import { Box, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as poiState from '../../../state';
import * as analysisState from '@/components/Analysis/state';
import { ActionButton, PanelCard } from './styles';
import { BLISTER_TYPE } from '@/constants';
import { allPointOfInterestTemplates } from '@/components/Analysis/state';
import {
  AllPointOfInterestTemplate,
  AnalysisDrawerRight,
  Point3d,
  PointOfInterestTemplateDocument,
} from '@/types';
import { titleCase, toggleValue } from '@/utils';
import { useImageFromLocationId } from '@/components/Analysis/Viewer/hooks/useImageFromLocationId';
import { useCallback } from 'react';

export const MeasureAutoPointOfInterest = ({
  pointOfInterest,
  disableGutters,
}: {
  pointOfInterest: PointOfInterest;
  disableGutters?: Boolean | undefined;
}) => {
  const [editPointOfInterest, setEditPointOfInterest] = useRecoilState(
    poiState.editPointOfInterest
  );
  const [drawerRight, setDrawerRight] = useRecoilState(analysisState.drawerRight);
  const [selectedSpherical, setSpherical] = useRecoilState(analysisState.selectedSpherical);
  const setBlisterToAdd = useSetRecoilState(poiState.blisterToAdd);
  const setDrawerRightPreviousState = useSetRecoilState(analysisState.drawerRightPreviousState);
  const setCameraTarget = useSetRecoilState(analysisState.cameraTarget);
  const setWidgetTemplate = useSetRecoilState(poiState.selectedTemplate);
  const allViewpoints = useRecoilValue(analysisState.structureLocations);
  const allTemplates = useRecoilValue<AllPointOfInterestTemplate>(allPointOfInterestTemplates);

  const getSphericalFromId = useImageFromLocationId(allViewpoints);

  const handleRightToggle = useCallback(
    (property: keyof AnalysisDrawerRight) => {
      setDrawerRight(toggleValue<AnalysisDrawerRight>(drawerRight, property));
      setDrawerRightPreviousState(property);
    },
    [drawerRight, setDrawerRight, setDrawerRightPreviousState]
  );

  const handleInitPOI = () => {
    handleRightToggle('equipment');
    if (!selectedSpherical && pointOfInterest.location?.id) {
      const newSpherical = getSphericalFromId(pointOfInterest.location?.id);
      if (newSpherical?.name) {
        const { x, y, z } = pointOfInterest.centerPoint3d || {};
        if (!x || !y || !z) return;
        const lookAt: Point3d = { x, y, z };
        setCameraTarget({
          position: newSpherical.position,
          lookAt: [x, y, z],
        });
        if (newSpherical) {
          setSpherical({
            ...newSpherical,
            lookAt,
          });
        }
      }
    }
    const template = allTemplates.find(
      ({ name }) => name === titleCase(BLISTER_TYPE)
    ) as PointOfInterestTemplateDocument;
    setWidgetTemplate(template);
    setBlisterToAdd({ state: 'WaitingForCenter' });
    setEditPointOfInterest({
      ...editPointOfInterest,
      pointOfInterest: pointOfInterest,
      isAutoGenerated: true,
      poiSphericalId: pointOfInterest.location?.id,
      template,
    });
  };

  return (
    <>
      {pointOfInterest.type === BLISTER_TYPE && !pointOfInterest.blister && (
        <Box
          paddingX={disableGutters ? '0px' : '2.4rem'}
          paddingTop={disableGutters ? '0px' : '3.2rem'}
        >
          <PanelCard>
            <img src="/assets/blister-profile.png" alt="Blister Profile" width="100%" />
            <Typography
              color={abyssColors.primary[400]}
              fontSize="1.2rem"
              lineHeight="normal"
              textAlign="center"
              marginTop="1.6rem"
              marginBottom="1rem"
            >
              No profile available for this Auto POI. Click “Generate Profile” to create one.
            </Typography>
            <ActionButton compact contained onClick={handleInitPOI}>
              Generate profile
            </ActionButton>
          </PanelCard>
        </Box>
      )}
    </>
  );
};
