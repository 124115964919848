import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Box,
  Divider,
  Stack,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { theme } from '@/theme/theme';
import {
  FileNameTypes,
  useDeleteFilesMutation,
  useUploadFilesMutation,
} from '@/__generated__/graphql';
import { FileUploadStatus } from '@/__generated__/graphql';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { Attachment } from './type';
import validateFiles from '../../modules/pointOfInterest/PointOfInterestTab/SelectedPoiContent/Tabs/ValidateFiles';
import { FileDisplay } from './FileDisplay';

type Props = {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  referenceId: string;
  collectionName: string;
  attachments: Attachment[];
  dialogTitle: string;
  buttonText: string;
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
};

const convertToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Result of file reading was not a string.'));
      }
    });
    reader.addEventListener('error', () => reject(reader.error));
  });

export const UploadFileDialog = ({
  openDialog,
  setOpenDialog,
  referenceId,
  collectionName,
  attachments,
  setAttachments,
  setIsUpdating,
  buttonText,
  dialogTitle,
}: Props) => {
  const structureId = useGetStructureId();
  const [uploadedAttachments, setUploadedAttachments] = useState<Attachment[]>([]);

  const [removeAttachments, setRemoveAttachments] = useState<string[]>([]);
  const [uploadFiles] = useUploadFilesMutation();
  const [deleteFiles] = useDeleteFilesMutation();
  const { showSnackBar } = useSnackBarMessage({
    variant: 'filled',
  });

  useEffect(() => {
    setUploadedAttachments(attachments);
  }, [attachments]);

  const handleAddAttachment = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (uploadedAttachments.length + (files?.length || 0) > 5) {
      showSnackBar('You can only upload a maximum of 5 files in total.', 'error');
      return;
    }
    if (files && files.length > 0) {
      const fileArray = [...files];
      const { validFiles, fileErrors } = validateFiles(fileArray);

      if (fileErrors.length > 0) {
        showSnackBar('You can only upload a maximum of 5 files in total.', 'error');
        fileErrors.map((error) => showSnackBar(error, 'error'));
        return;
      }

      const duplicateFiles = validFiles.filter((file) =>
        uploadedAttachments.some((att) => att.name === file.name.replace(/[^\w.-]/g, '_'))
      );

      if (duplicateFiles.length > 0) {
        showSnackBar(
          `Duplicate file names found: ${duplicateFiles.map((file) => file.name).join(', ')}`,
          'error'
        );

        return;
      }

      const attachmentsPayload: Attachment[] = await Promise.all(
        validFiles.map(async (file, index) => {
          const isImageOrVideo = file.type.startsWith('image/');
          const base64 = isImageOrVideo ? await convertToBase64(file) : '';
          return {
            uuid: `file-${Date.now()}-${index}`,
            name: file.name,
            type: file.type,
            base64: base64,
            isNew: true,
            size: file.size,
            file,
            uploadStatus: FileUploadStatus.Inprogress || 'INPROGRESS',
          };
        })
      );
      setUploadedAttachments((previousAttachments) => [
        ...previousAttachments,
        ...attachmentsPayload,
      ]);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    const newAttachments = uploadedAttachments.filter((_, subIndex) => subIndex !== index);
    if (
      uploadedAttachments[index] &&
      !uploadedAttachments[index].isNew &&
      uploadedAttachments[index].id
    ) {
      setRemoveAttachments((previousRemovedAttachments) => [
        ...previousRemovedAttachments,
        uploadedAttachments[index].id || '',
      ]);
    }
    setUploadedAttachments(newAttachments);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setRemoveAttachments([]);
    setUploadedAttachments(attachments);
  };

  const handleUpdate = async () => {
    const attachmentsToBeUploaded = uploadedAttachments.filter((file) => file.isNew);
    setOpenDialog(false);
    if (removeAttachments.length > 0) {
      setIsUpdating(true);

      setAttachments(() =>
        attachments.filter(
          (attachment) => attachment.id && !removeAttachments.includes(attachment.id)
        )
      );
      await deleteFiles({
        variables: {
          input: {
            referenceId,
            collectionName,
            fileIds: removeAttachments,
          },
        },
        onCompleted: () => {
          setIsUpdating(false);
          showSnackBar('Attachments have been successfully updated', 'success');
        },
        onError: (error) => {
          setIsUpdating(false);
          showSnackBar(error.message, 'error');
        },
      });
    }
    if (attachmentsToBeUploaded.length > 0) {
      setAttachments((previousAttachments) => [...previousAttachments, ...attachmentsToBeUploaded]);
      setIsUpdating(true);

      try {
        const { data } = await uploadFiles({
          variables: {
            input: {
              structureId,
              collectionName,
              referenceId,
              files: attachmentsToBeUploaded.map(({ uuid, name, type, size }) => ({
                uuid: uuid || '',
                name,
                size: size || 0,
                mimeType: type,
              })),
              fileNameType: FileNameTypes.UserAttachments,
            },
          },
          onError: (error) => {
            setIsUpdating(false);
            showSnackBar(error.message, 'error');
            return;
          },
        });
        if (data?.preSignedUrlToUploadFiles && data?.preSignedUrlToUploadFiles?.length > 0) {
          const updatedAttachments = uploadedAttachments.map((attachment) => {
            const uploadedFile = data?.preSignedUrlToUploadFiles?.find(
              (uploaded) => uploaded.uuid === attachment.uuid
            );
            if (uploadedFile) {
              return {
                ...attachment,
                url: uploadedFile.url,
                resourcePath: uploadedFile.resourcePath,
                id: uploadedFile.id,
              };
            }
            return attachment;
          });
          setAttachments(updatedAttachments);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        setOpenDialog(false);
      }
    }

    setRemoveAttachments([]);
    setUploadedAttachments([]);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ minHeight: '200px' }}>
          <Typography variant="caption" sx={{ color: theme.palette.primary.dark }}>
            Upload an image, video or pdf{'  '}
          </Typography>
          <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
            (max 5 uploads)
          </Typography>
          <Grid container spacing={2} sx={{ my: 1 }}>
            <label htmlFor="file-upload" style={{ marginLeft: '15px' }}>
              <input
                accept="image/jpeg,image/png,application/pdf,video/mp4"
                style={{ display: 'none' }}
                id="file-upload"
                multiple
                type="file"
                onChange={handleAddAttachment}
              />
              <Box
                sx={{
                  border: '1px dashed #B8CADD',
                  p: '16px',
                  borderRadius: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '14px',
                }}
              >
                <ArrowUpwardIcon style={{ color: theme.palette.text.secondary }} />
                <Typography
                  variant="caption"
                  style={{ color: theme.palette.text.secondary, marginTop: 8 }}
                >
                  Upload
                </Typography>
              </Box>
            </label>
            {uploadedAttachments.map((attachment, index) => (
              <Grid item key={attachment.id || attachment.name} xs={2} md={2} sm={3}>
                <FileDisplay
                  referenceId={referenceId}
                  collectionName={collectionName}
                  selectedAttachment={attachment}
                  selectedAttachmentIndex={index}
                  handleRemoveAttachment={handleRemoveAttachment}
                  showRemoveIcon
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between', px: 2 }}>
        <Stack direction="row" justifyContent="flex-start" spacing={2} sx={{ mx: '12px' }}></Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={handleClose} color="primary" variant="outlined" sx={{ borderRadius: 2 }}
            id="media-upload-dialog-cancel-button"
          >
            Cancel
          </Button>
          <Button
            id="media-upload-dialog-update-button"
            onClick={handleUpdate}
            color="primary"
            variant="contained"
            sx={{ borderRadius: 2, background: theme.palette.primary.dark, marginRight: '12px' }}
          >
            {buttonText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileDialog;
