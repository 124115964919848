import React, { memo, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import 'rc-dock/dist/rc-dock.css';
import DockLayout, {
  BoxBase,
  DropDirection,
  LayoutBase,
  PanelBase,
  TabBase,
  TabData,
  TabGroup,
} from 'rc-dock';
import { taggingPanelsWithDragDisabled } from '@/utils';
import styles from './styles.module.css';
import * as state from '@/state';
import { useRecoilValue, useSetRecoilState } from 'recoil';

type Props = {
  dockLayout?: React.RefObject<DockLayout>;
  topSidebarTabs?: TabData[];
  bottomSidebarTabs?: TabData[];
  marginTop?: number;
  sidebarSize?: 'small' | 'large';
};

const DockBase = ({
  dockLayout,
  children,
  topSidebarTabs,
  bottomSidebarTabs,
  marginTop,
  sidebarSize = 'small',
}: PropsWithChildren<Props>) => {
  const [layout, setLayout] = useState<LayoutBase | undefined>();
  const [tabs, setTabs] = useState<{ [tabId: string]: TabData }>({});
  const setSphericalPointCloudMode = useSetRecoilState(state.sphericalPointCloudMode);
  const inSphericalView = useRecoilValue(state.inSphericalView);
  const tabGroups: {
    [key: string]: TabGroup;
  } = {
    default: {
      // Disabling maximise button for now because maximising spatial viewer tab causes a remount of spatial viewer
      // which resets the viewer camera position and mode.
      // Can revert this if/when we fix that issue
      maximizable: false,
    },
  };

  useEffect(() => {
    if (inSphericalView) {
      setSphericalPointCloudMode('Related');
    }
  }, [inSphericalView, setSphericalPointCloudMode]);

  useEffect(() => {
    const tabsData: { [tabId: string]: TabData } = {};

    const mainArea: PanelBase = {
      id: 'main',
      tabs: [],
      size: 400,
    };

    // Set up main panel tabs from children
    if (children) {
      React.Children.forEach(children, (tab, index) => {
        if (!React.isValidElement(tab)) {
          throw new Error('Tab content must be a React element');
        }

        const tabId = `main-${index}`;
        tabsData[tabId] = {
          id: tabId,
          title: 'Spatial Viewer',
          closable: false,
          content: tab,
          group: 'default',
        };

        mainArea.tabs.push({ id: tabId });
      });
    }

    const sidebarSizeValue = sidebarSize === 'small' ? 100 : 350;

    // Set up left sidebar panels
    const interactionPanels: BoxBase = {
      mode: 'vertical',
      size: sidebarSizeValue,
      id: 'initial_sidebar',
      children: [],
    };

    if (topSidebarTabs && topSidebarTabs.length > 0) {
      const topInteractionPanel: PanelBase = {
        size: sidebarSizeValue,
        tabs: [],
      };
      topSidebarTabs?.forEach((sidebar, index) => {
        const tabId = sidebar.id ?? `t-${index}`;
        topInteractionPanel.tabs.push({
          id: tabId,
        });

        tabsData[tabId] = {
          id: tabId,
          title: sidebar.title,
          closable: false,
          content: sidebar.content,
          group: 'default',
        };
      });
      interactionPanels.children.push(topInteractionPanel);
    }

    if (bottomSidebarTabs && bottomSidebarTabs.length > 0) {
      const bottomInteractionPanel: PanelBase = {
        size: sidebarSizeValue,
        tabs: [],
      };
      bottomSidebarTabs?.forEach((sidebar, index) => {
        const tabId = sidebar.id ?? `b-${index}`;
        bottomInteractionPanel.tabs.push({
          id: tabId,
        });

        tabsData[tabId] = {
          id: tabId,
          title: sidebar.title,
          closable: false,
          content: sidebar.content,
          group: 'default',
        };
      });
      interactionPanels.children.push(bottomInteractionPanel);
    }

    const layoutData: LayoutBase = {
      dockbox: {
        mode: 'horizontal',
        children: [interactionPanels, mainArea],
      },
    };

    setLayout(layoutData);
    setTabs(tabsData);
  }, [bottomSidebarTabs, children, topSidebarTabs, sidebarSize]);

  const handleLayoutChange = useCallback(
    (
      newLayout: LayoutBase,
      currentTabId?: string | undefined,
      direction?: DropDirection | undefined
    ) => {
      // Whenever drop directions of isolated_part, zone_selector, visibility, part_selector changes;
      // Visibility tab becomes de-activated randomly.
      // So, we are blocking all drag operations on tagging related tabs except whenever user clicks it.
      if (
        currentTabId &&
        taggingPanelsWithDragDisabled.includes(currentTabId) &&
        direction !== 'active'
      ) {
        return;
      }

      setLayout(newLayout);

      // This is a workaround to allow plotly responsiveness to work with dock panel resize instead of just window resize
      window.dispatchEvent(new Event('resize'));
    },
    []
  );

  const loadTab = useCallback(
    (tab: TabBase) => {
      const { id } = tab;
      return id ? tabs[id] : { title: '', content: <></> };
    },
    [tabs]
  );

  if (!layout) {
    return <></>;
  }

  return (
    <div className={styles.abyssdock}>
      <DockLayout
        ref={dockLayout}
        layout={layout}
        groups={tabGroups}
        loadTab={loadTab}
        onLayoutChange={handleLayoutChange}
        style={{ position: 'absolute', left: 10, top: marginTop ?? 125, right: 10, bottom: 50 }}
      />
    </div>
  );
};

const areTabsEqual = (
  previousTabs: TabData[] | undefined,
  nextTabs: TabData[] | undefined
): boolean => {
  if (previousTabs?.length !== nextTabs?.length) {
    return false;
  }

  if (previousTabs && nextTabs) {
    // eslint-disable-next-line no-plusplus
    for (let t = 0; t < previousTabs.length; t++) {
      if (previousTabs[t].title !== nextTabs[t].title) {
        return false;
      }
    }
  }

  return true;
};

const arePropsEqual = (previousProps: Props, nextProps: Props): boolean => {
  return (
    previousProps.sidebarSize === nextProps.sidebarSize &&
    areTabsEqual(previousProps.topSidebarTabs, nextProps.topSidebarTabs) &&
    areTabsEqual(previousProps.bottomSidebarTabs, nextProps.bottomSidebarTabs)
  );
};

export const Dock = memo(DockBase, arePropsEqual);
