import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { primary } from '@/theme/colors';
import { DefaultLayout } from '@/layouts';

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '50rem',
  width: '50rem',
  backgroundColor: 'rgba(101, 130, 164, 0.1)',
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const buttonStyles = {
  fontSize: '1.5rem',
  height: '5rem',
  width: '13rem',
  marginTop: '2rem',
  backgroundColor: primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: primary.dark,
  },
};

const BackIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_11148_14856)">
          <path
            d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 11V8L8 12L12 16V13H16V11H12Z"
            fill="#F6F8FB"
          />
        </g>
        <defs>
          <clipPath id="clip0_11148_14856">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

type ErrorScreenProps = {
  statusCode?: string;
  errorMessage?: string;
  buttonText?: string;
  buttonURL?: string;
  buttonIcon?: JSX.Element;
};

export const ErrorScreen = ({
  statusCode,
  errorMessage,
  buttonText,
  buttonURL,
  buttonIcon,
}: ErrorScreenProps) => {
  const router = useRouter();
  const { statusCode: statusCodeFromURL } = router.query;

  return (
    <div data-testid="error-screen">
      <DefaultLayout>
        <Box sx={styles}>
          <Typography sx={{ fontSize: '5rem', color: '#6582A4', fontWeight: '600' }}>
            {statusCode || statusCodeFromURL || '404'}
          </Typography>
          <Typography sx={{ fontSize: '2rem', color: '#6582A4' }}>
            {errorMessage ||
              (statusCodeFromURL === '403'
                ? 'Access is denied, you lack permission'
                : statusCode === '404'
                ? 'The requested page/resource is not available'
                : 'The requested page/resource is not available')}
          </Typography>
          <Button
            sx={buttonStyles}
            startIcon={buttonIcon ?? <BackIcon />}
            onClick={() => router.push(buttonURL || '/')}
          >
            {buttonText || 'GO BACK'}
          </Button>
        </Box>
      </DefaultLayout>
    </div>
  );
};
