import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import TuneIcon from '@mui/icons-material/Tune';
import { Visibility } from '../Panels/Visibility';

import * as state from '@/components/Analysis/state';

import { AnalysisDrawerLeft, AnalysisDrawerRight } from '@/types';
import { AssemblyTabs } from '@/components/Analysis/Viewer/AssemblyTabs';
import { useFeatureFlag } from '@/hooks';
import { QUERY_DRAWER_COLOR } from '@/constants';
import { FilterSideBar } from '@/components/Analysis/shared/FilterSideBar/FilterSideBar';
import {
  GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery,
  useGetAssembliesByTemplateFieldsFiltersForEquipmentsTabLazyQuery,
} from '@/__generated__/graphql';
import { SideDrawer } from '@/components/shared/SideDrawer';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useMeasurementTool } from '../../modules/measurementTool';
import { closeAll, toggleValue } from '@/utils';
import { useFindViewpointsTool } from '../../modules/findViewpointsTool';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';

const AitInsightsLazy = lazy(() =>
  import('./AitInsights').then(({ AitInsights }) => ({
    default: AitInsights,
  }))
);

const MeasuringToolLazy = lazy(() =>
  import('./MeasuringTool').then(({ MeasuringTool }) => ({
    default: MeasuringTool,
  }))
);

const FindViewpointsToolLazy = lazy(() =>
  import('./FindViewpointsTool').then(({ FindViewpointsTool }) => ({
    default: FindViewpointsTool,
  }))
);

type DrawersProps = {
  onAssemblyLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Drawers = ({ onAssemblyLoading }: DrawersProps) => {
  const structureId = useGetStructureId();

  const isAitEngineer = useFeatureFlag(['ait-engineer', 'ait-task-lead']);
  const isAdmin = useFeatureFlag('admin');

  const [previousDrawerLeft, setPreviousDrawerLeft] = useState<AnalysisDrawerLeft>();
  const [filteredAssemblies, setFilteredAssemblies] =
    useState<
      GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery['getAssembliesByTemplateFieldsFilters']
    >();

  const [assemblyLoading, setAssemblyLoading] = useState(false);

  const [drawerLeft, setDrawerLeft] = useRecoilState(state.drawerLeft);
  const [drawerRight, setDrawerRight] = useRecoilState(state.drawerRight);
  const setDrawerRightPreviousState = useSetRecoilState(state.drawerRightPreviousState);
  const [applyFilters, setApplyFilters] = useRecoilState(state.applyFilters);
  const [selectedAssemblyId, setSelectedAssemblyId] = useRecoilState(state.selectedAssemblyId);
  const [selectedTemplateFilters, setSelectedTemplateFilters] = useRecoilState(
    state.selectedTemplateFiltersState
  );
  const [selectedDeckIds, setSelectedDeckIds] = useRecoilState(state.selectedFilteredDeckIds);
  const [selectedPaintRegionIds, setSelectedPaintRegionIds] = useRecoilState(
    state.selectedFilteredPaintRegionIds
  );

  const selectedAssemblyName = useRecoilValue(state.selectedAssemblyName);
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const isUncontextualised = useRecoilValue(state.isUncontextualised);

  useEffect(() => {
    setApplyFilters(true);
  }, [setApplyFilters]);

  const selectedTemplateId = useRecoilValue(state.selectedAssemblyTemplateId);
  const { leftDrawerWidth } = useDrawerWidths();
  const handleLeftToggle = useCallback(
    (property: keyof AnalysisDrawerLeft) => {
      setDrawerLeft(toggleValue<AnalysisDrawerLeft>(drawerLeft, property));
    },
    [drawerLeft, setDrawerLeft]
  );

  const handleRightToggle = useCallback(
    (property: keyof AnalysisDrawerRight) => {
      setDrawerRight(toggleValue<AnalysisDrawerRight>(drawerRight, property));
      setDrawerRightPreviousState(property);
    },
    [drawerRight, setDrawerRight, setDrawerRightPreviousState]
  );

  const leftDrawersClosed = useMemo(() => {
    return !Object.values(drawerLeft).includes(true);
  }, [drawerLeft]);

  const rightDrawersClosed = useMemo(() => {
    return !Object.values(drawerRight).includes(true);
  }, [drawerRight]);

  useEffect(() => {
    if (isUncontextualised !== undefined) {
      setDrawerLeft({
        visibility: isUncontextualised,
        query: !isUncontextualised,
      });
    }
  }, [isUncontextualised, setDrawerLeft]);

  useEffect(() => {
    if (selectedAssemblyId) {
      setDrawerLeft((previousDrawerLeft_) => {
        setPreviousDrawerLeft(previousDrawerLeft_);
        return closeAll(previousDrawerLeft_);
      });
    }
  }, [selectedAssemblyId, setDrawerLeft]);

  useEffect(() => {
    if (!selectedAssemblyId && previousDrawerLeft) {
      setDrawerLeft(previousDrawerLeft);
    }
  }, [selectedAssemblyId, previousDrawerLeft, setDrawerLeft]);
  const [fetchFilteredAssemblies] =
    useGetAssembliesByTemplateFieldsFiltersForEquipmentsTabLazyQuery();
  const defaultFiltersForAssemblies = useMemo(() => {
    return [selectedTemplateFilters, selectedPaintRegionIds, selectedDeckIds].every(
      (filters) => filters.length === 0
    );
  }, [selectedDeckIds, selectedTemplateFilters, selectedPaintRegionIds]);

  useEffect(() => {
    if (selectedTemplateId && applyFilters) {
      fetchFilteredAssemblies({
        variables: {
          input: {
            selectedTemplateFilters,
            structureId,
            templateId: selectedTemplateId ?? '',
            selectedDeckIds,
            selectedPaintRegionIds,
          },
        },
        fetchPolicy: 'no-cache',
      })
        .then(({ data }) => {
          setFilteredAssemblies(data?.getAssembliesByTemplateFieldsFilters);
          setApplyFilters(false);
        })
        .finally(() => setAssemblyLoading(false));
    }
  }, [
    defaultFiltersForAssemblies,
    selectedTemplateId,
    fetchFilteredAssemblies,
    selectedTemplateFilters,
    structureId,
    setAssemblyLoading,
    selectedDeckIds,
    selectedPaintRegionIds,
    applyFilters,
    setApplyFilters,
  ]);

  useEffect(() => {
    if (filteredAssemblies && selectedAssemblyName && !selectedAssemblyId) {
      setSelectedAssemblyId(
        filteredAssemblies.find((assembly) => assembly?.tagName === selectedAssemblyName)?.id ??
          undefined
      );
    }
  }, [filteredAssemblies, selectedAssemblyId, selectedAssemblyName, setSelectedAssemblyId]);

  const clearQuery = useCallback(() => {
    setAssemblyLoading(true);
    setFilteredAssemblies(undefined);
  }, [setFilteredAssemblies]);

  useEffect(() => {
    if (onAssemblyLoading) {
      onAssemblyLoading(assemblyLoading);
    }
  }, [assemblyLoading, onAssemblyLoading]);

  const { isMeasurementMenuVisible } = useMeasurementTool();

  const { isFindViewpointsToolActive } = useFindViewpointsTool();

  useEffect(() => {
    if (
      selectedAssemblyId &&
      !filteredAssemblies?.some((assembly) => assembly.id === selectedAssemblyId)
    ) {
      setSelectedTemplateFilters([]);
      setSelectedDeckIds([]);
      setSelectedPaintRegionIds([]);
      setApplyFilters(true);
    }
  }, [
    filteredAssemblies,
    selectedAssemblyId,
    setApplyFilters,
    setSelectedDeckIds,
    setSelectedTemplateFilters,
    setSelectedPaintRegionIds,
  ]);
  return (
    <div data-testid="drawers">
      <AssemblyTabs
        filteredAssemblies={filteredAssemblies}
        assemblyLoading={assemblyLoading}
        allClosed={rightDrawersClosed}
        isOpen={drawerRight.equipment}
        handleOpen={() => handleRightToggle('equipment')}
      />
      {(isAitEngineer || isAdmin) && (
        <Suspense>
          <AitInsightsLazy
            allClosed={rightDrawersClosed}
            isOpen={drawerRight.aitInsight}
            handleOpen={() => handleRightToggle('aitInsight')}
          />
        </Suspense>
      )}

      {isMeasurementMenuVisible && (
        <Suspense>
          <MeasuringToolLazy
            allClosed={rightDrawersClosed}
            isOpen={drawerRight.measuringTool}
            handleOpen={() => handleRightToggle('measuringTool')}
          />
        </Suspense>
      )}

      {isFindViewpointsToolActive && (
        <Suspense>
          <FindViewpointsToolLazy
            allClosed={rightDrawersClosed}
            isOpen={drawerRight.findViewpointsTool}
            handleOpen={() => handleRightToggle('findViewpointsTool')}
          />
        </Suspense>
      )}

      <Visibility
        allClosed={leftDrawersClosed}
        isOpen={drawerLeft.visibility}
        handleOpen={() => handleLeftToggle('visibility')}
        data-testid="visibility-drawer"
      />

      {!selectedSpherical && !selectedAssemblyId && (
        <SideDrawer
          allDrawersClosed={leftDrawersClosed}
          isOpen={drawerLeft.query}
          handleOpen={() => handleLeftToggle('query')}
          icon={isUncontextualised ? undefined : <TuneIcon />}
          position="left"
          drawerColor={QUERY_DRAWER_COLOR}
          width={leftDrawerWidth}
          label="Query"
          iconStyles={{ top: 65 }}
          data-testid="filter-drawer"
        >
          <FilterSideBar onClear={clearQuery} />
        </SideDrawer>
      )}
    </div>
  );
};
