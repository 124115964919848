import { Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { primary } from '@/theme/colors';

type Props = {
  message?: string;
  graphicSrc?: string;
  type: 'poiTable' | 'equipmentTable' | 'equipmentViewer' | 'version' | 'template';
};

const PlaceholderDetails = {
  poiTable: {
    message: "Click on any POI's row in the table to see its information",
    graphicSrc: '/assets/row-selection-graphic.svg',
  },
  equipmentTable: {
    message: "Click on any component's row in the table to see its information",
    graphicSrc: '/assets/row-selection-graphic.svg',
  },
  equipmentViewer: {
    message: 'Click on any component from the viewer to see its information',
    graphicSrc: '/assets/equipment-select-graphic.svg',
  },
  version: {
    message: "Click on any component's row in the table to see its versions",
    graphicSrc: '/assets/equipment-select-graphic.svg',
  },
  template: {
    message: 'No templates found',
    graphicSrc: '/assets/row-selection-graphic.svg',
  },
};

export const EmptyStatePlaceholder = ({ message, graphicSrc, type }: Props) => {
  return (
    <Stack width="100%" direction="row" justifyContent="center">
      <Stack
        maxWidth={{ md: '192px', xl: '230px' }}
        direction="column"
        alignItems="center"
        sx={{
          img: {
            maxWidth: { md: '100px', xl: '133px' },
          },
        }}
      >
        <img
          src={graphicSrc || PlaceholderDetails[type].graphicSrc}
          alt="No selection"
          style={{ width: 'auto' }}
        />
        <Stack
          sx={{ marginTop: { md: '20px', xl: '40px' } }}
          direction="row"
          alignItems="center"
          gap={1}
        >
          <InfoIcon htmlColor={primary.light} />
          <Typography fontSize="1.2rem" color={primary.light}>
            {message || PlaceholderDetails[type].message}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
