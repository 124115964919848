import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GenericAccordionProps } from '@/types';
import { accordianRootStyles, indicatorStyles } from './styles';
import { abyssColors } from '@/theme/colors';

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: 'none',
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export const GenericAccordion = ({
  summary,
  children,
  numericIndicator,
  openByDefault,
  summaryStyles,
  customSummaryChip,
  summaryIcon,
}: GenericAccordionProps) => {
  
  return (
    <StyledAccordion
      elevation={0}
      sx={{ px: '2.4rem', ...accordianRootStyles }}
      defaultExpanded={openByDefault}
    >
      <AccordionSummary
        sx={{ px: 0, py: 1, ...accordianRootStyles }}
        expandIcon={
          <ExpandMoreIcon
            sx={{ color: abyssColors.primary[500], transform: 'rotate(-90deg)', mr: '0.4rem' }}
          />
        }
      >
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          sx={{
            width: '100%',
          }}
        >
          <Box mr="auto">
            <Typography sx={{ color: abyssColors.primary[500], ...summaryStyles }}>
              {summary}
              {customSummaryChip && customSummaryChip}
            </Typography>
            {numericIndicator && <Chip sx={indicatorStyles} label={numericIndicator} />}
          </Box>
          <Box>{summaryIcon && summaryIcon}</Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ mb: 2 }}>{children}</AccordionDetails>
    </StyledAccordion>
  );
};
