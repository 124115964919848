import { renderToString } from 'react-dom/server';
import { POI_DETAILS_DEFAULT, POI_DETAILS_FALLBACK } from '@/constants';

type PointofInterestIconProps = {
  type: string;
  size?: number;
  borderSize?: number;
  opacity?: number;
  selectedPoi?: boolean;
  poiId?: string;
  poiIconDetail?: Array<{
    __typename?: 'PointOfInterestTemplate';
    id: string;
    name: string;
    icon?: {
      __typename?: 'TemplateIcon';
      name: string;
      color?: string | null;
    } | null;
  } | null> | null;
  overrideColor?: string;
  poiIcon: string | undefined;
  iconColor?: string | undefined;
  isUncertain?: boolean;
  isGoverning?: boolean;
  isSelected?: boolean;
};

const defaultIconSize = 8; // px

//const defaultAvatarBorderSize = 2.1; // px
const defaultOpacity = 0.8;

export const pointOfInterestIcon = ({
  type,
  //selectedPoi,
  size = defaultIconSize,
  //borderSize = defaultAvatarBorderSize, TO Be removed later
  opacity = defaultOpacity,
  iconColor,
  overrideColor,
  poiIcon,
  isUncertain,
  isGoverning,
  isSelected,
}: PointofInterestIconProps): string => {
  const fallbackIcon = POI_DETAILS_FALLBACK.find(
    (fallbackDetail) => fallbackDetail.name === type
  )?.icon;

  const fallbackIconColor = POI_DETAILS_FALLBACK.find(
    (fallbackDetail) => fallbackDetail.name === type
  )?.iconColor;
  const iconComp =
    poiIcon && poiIcon.length > 0
      ? poiIcon
      : renderToString(fallbackIcon) ?? renderToString(POI_DETAILS_DEFAULT.icon);
  const color =
    overrideColor ??
    iconColor ??
    fallbackIconColor ??
    (isUncertain ? POI_DETAILS_DEFAULT.uncertainIconColor : POI_DETAILS_DEFAULT.iconColor);
  const fillString = `%23${color.replace('#', '')}`; // %23 is escape code for #
  const transformString = `scale(${isSelected ? '0.36' : '0.3'}) translate(-${size * 1.5} -${size * 4.5})`;
  // To prevent styles from being inherited a slightly different color is used i.e. white vs rgb(254,254,254) | black vs rgb(1,1,1)
  const selectedIcon = isSelected
    ? `
      <svg viewBox="-6.4 -3.2 13.2 4.2" width="13.2" height="4.2" xmlns="http://www.w3.org/2000/svg">
        <g id="selectedShadow" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -9.000000008381903, -17.200000778958202)">
          <ellipse id="shadow" class="shadow-opacity" fill="rgb(250,250,250)" fill-opacity="0.6" cx="30" cy="53" rx="22" ry="7" stroke-width="0.2" stroke="rgb(254,254,254)" stroke-opacity="0.7" transform="scale(0.3)"/>
        </g>
        <g id="g-1" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -2.9989950684821194, -7.119999892190099)">
          <ellipse id="ellipse-1" class="shadow-opacity" fill-opacity="0.1" cx="2.999" cy="5.82" rx="2.2" ry="0.7" stroke-opacity="0.7" style="fill: rgb(0, 0, 0);"/>
        </g>
        <g id="g-2" transform="matrix(1.6310679912567136, 0, 0, 1.9423921108245847, -4.896687989026949, -12.595949184704525)" style="">
          <ellipse id="ellipse-2" class="shadow-opacity" fill-opacity="0.1" cx="2.999" cy="5.82" rx="2.2" ry="0.7" stroke-opacity="0.7" style="fill: rgb(0, 0, 0);"/>
        </g>
      </svg>
  `
    : '';
  const governingIcon = isGoverning
    ? `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="${isSelected ? 'scale(1.2)' : ''} translate(-2, -17)">
      <circle cx="6.5" cy="6.5" r="1.85" fill="%23E4A31B"/>
      <g clip-path="url(%23clip0_9630_53012)">
        <path d="M2.33334 9.41927H10.6667V10.2526H2.33334V9.41927ZM2.33334 3.58594L4.41668 5.04427L6.50001 2.33594L8.58334 5.04427L10.6667 3.58594V8.58594H2.33334V3.58594ZM3.16668 5.18635V7.7526H9.83334V5.18635L8.40834 6.18385L6.50001 3.7026L4.59168 6.18385L3.16668 5.18594V5.18635Z"
        fill="%23F6F8FB"
        transform="translate(4.6, 4.5) scale(0.3)"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9630_53012">
        <rect width="10" height="10" fill="white" transform="translate(1.5 1.50195)"/>
      </clipPath>
    </defs>
  </svg>
  `
    : '';
  const svg = `<svg>
    ${selectedIcon}
    <g transform="${isSelected ? 'scale(1.2)' : ''} translate(-6,-12.5)">
      <path fill-opacity="${opacity}" stroke="${isSelected ? 'black' : 'rgb(1,1,1)'}" stroke-opacity="${isSelected ? '1' : '0.16'}" stroke-width="0.9" d="M6 0.596a4.758 4.758 0 1 1 0 9.516 4.758 4.758 0 0 1 0-9.516z"/>
      <path d="M 7.235 10.127 C 7.248 10.516 6.434 11.39 6.045 11.404 C 5.656 11.417 4.783 10.603 4.769 10.213 C 4.756 9.824 5.607 10.009 5.996 9.994 C 6.384 9.981 7.22 9.738 7.235 10.127 Z" fill="white" stroke="${isSelected ? 'black' : 'rgb(1,1,1)'}" stroke-opacity="${isSelected ? '1' : '0.16'}" stroke-width="0.2"/>
      <path fill="${fillString}" fill-opacity="${opacity}" stroke="white" stroke-width="0.5" d="M6 0.596a4.758 4.758 0 1 1 0 9.516 4.758 4.758 0 0 1 0-9.516z"/>
    </g>
    <g transform="${transformString}" fill="white">${iconComp}</g>
    ${governingIcon}
  </svg>`;
  return svg;
};

const defaultHoveredOpacity = 1;
export const pointOfInterestHoveredIcon = ({
  type,
  size = defaultIconSize,
  //  borderSize = defaultAvatarBorderSize, TODO
  opacity = defaultHoveredOpacity,
  //  selectedPoi, TO be removed later
  iconColor,
  overrideColor,
  poiIcon,
  isGoverning,
  isSelected,
}: PointofInterestIconProps): string => {
  const fallbackIcon = POI_DETAILS_FALLBACK.find(
    (fallbackDetail) => fallbackDetail.name === type
  )?.icon;
  const iconComp =
    poiIcon ?? renderToString(fallbackIcon) ?? renderToString(POI_DETAILS_DEFAULT.icon);
  const fallbackIconColor = POI_DETAILS_FALLBACK.find(
    (fallbackDetail) => fallbackDetail.name === type
  )?.iconColor;
  const color = overrideColor ?? iconColor ?? fallbackIconColor ?? POI_DETAILS_DEFAULT.iconColor;
  const fillString = `%23${color.replace('#', '')}`; // %23 is escape code for #
  const transformString = `scale(${isSelected ? '0.36' : '0.3'}) translate(-${size * 1.5} -${size * 4.5})`;
  // To prevent styles from being inherited a slightly different color is used i.e. white vs rgb(254,254,254) | black vs rgb(1,1,1)
  const selectedIcon = isSelected
    ? `
      <svg viewBox="-6.4 -3.2 13.2 4.2" width="13.2" height="4.2" xmlns="http://www.w3.org/2000/svg">
        <g id="selectedShadow" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -9.000000008381903, -17.200000778958202)">
          <ellipse id="shadow" class="shadow-opacity" fill="rgb(250,250,250)" fill-opacity="0.6" cx="30" cy="53" rx="22" ry="7" stroke-width="0.2" stroke="rgb(254,254,254)" stroke-opacity="0.7" transform="scale(0.3)"/>
        </g>
        <g id="g-1" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -2.9989950684821194, -7.119999892190099)">
          <ellipse id="ellipse-1" class="shadow-opacity" fill-opacity="0.1" cx="2.999" cy="5.82" rx="2.2" ry="0.7" stroke-opacity="0.7" style="fill: rgb(0, 0, 0);"/>
        </g>
        <g id="g-2" transform="matrix(1.6310679912567136, 0, 0, 1.9423921108245847, -4.896687989026949, -12.595949184704525)" style="">
          <ellipse id="ellipse-2" class="shadow-opacity" fill-opacity="0.1" cx="2.999" cy="5.82" rx="2.2" ry="0.7" stroke-opacity="0.7" style="fill: rgb(0, 0, 0);"/>
        </g>
      </svg>
  `
    : '';
  const governingIcon = isGoverning
    ? `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="${isSelected ? 'scale(1.2)' : ''} translate(-2, -17)">
      <circle cx="6.5" cy="6.5" r="1.85" fill="%23E4A31B"/>
      <g clip-path="url(%23clip0_9630_53012)">
        <path d="M2.33334 9.41927H10.6667V10.2526H2.33334V9.41927ZM2.33334 3.58594L4.41668 5.04427L6.50001 2.33594L8.58334 5.04427L10.6667 3.58594V8.58594H2.33334V3.58594ZM3.16668 5.18635V7.7526H9.83334V5.18635L8.40834 6.18385L6.50001 3.7026L4.59168 6.18385L3.16668 5.18594V5.18635Z"
        fill="%23F6F8FB"
        transform="translate(4.6, 4.5) scale(0.3)"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9630_53012">
        <rect width="10" height="10" fill="white" transform="translate(1.5 1.50195)"/>
      </clipPath>
    </defs>
  </svg>
  `
    : '';
  return `<svg>
  ${selectedIcon}
  <g transform="${isSelected ? 'scale(1.2)' : ''} translate(-6,-12.5)">
    <path fill-opacity="${opacity}" stroke="${isSelected ? 'black' : 'rgb(1,1,1)'}" stroke-opacity="${isSelected ? '1' : '0.16'}" stroke-width="0.9" d="M6 0.596a4.758 4.758 0 1 1 0 9.516 4.758 4.758 0 0 1 0-9.516z"/>
    <path d="M 7.235 10.127 C 7.248 10.516 6.434 11.39 6.045 11.404 C 5.656 11.417 4.783 10.603 4.769 10.213 C 4.756 9.824 5.607 10.009 5.996 9.994 C 6.384 9.981 7.22 9.738 7.235 10.127 Z" fill="white" stroke="${isSelected ? 'black' : 'rgb(1,1,1)'}" stroke-opacity="${isSelected ? '1' : '0.16'}" stroke-width="0.2"/>
    <path fill="${fillString}" fill-opacity="${opacity}" stroke="white" stroke-width="0.5" d="M6 0.596a4.758 4.758 0 1 1 0 9.516 4.758 4.758 0 0 1 0-9.516z"/>
  </g>
  <g transform="${transformString}" fill="white">${iconComp}</g>
  ${governingIcon}
</svg>`;
};
