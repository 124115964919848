import { RecoilState, useSetRecoilState } from 'recoil';
import { Alert, AlertColor } from '@mui/material';
import { SnackbarMessage } from '@/types';
import { snackbarMessage as analysisSnackbarMessageAtom } from '@/components/Analysis/state';

type Props = {
  atom?: RecoilState<SnackbarMessage>;
  variant?: 'filled' | 'standard' | 'outlined';
};

export const useSnackBarMessage = ({
  atom = analysisSnackbarMessageAtom,
  variant = 'filled',
}: Props) => {
  const setSnackbarMessage = useSetRecoilState(atom);
  const showSnackBar = (message: string, severity: AlertColor) => {
    setSnackbarMessage({
      shouldShow: true,
      content: (
        <Alert sx={{ color: 'white' }} variant={variant} severity={severity}>
          {message && (
            <>
              {message}
              &nbsp;&nbsp;
            </>
          )}
        </Alert>
      ),
    });
  };

  return {
    showSnackBar,
  };
};
