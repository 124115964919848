import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { downloadFile, urlToBase64 } from '@/utils';
import {
  FileTypes,
  FileUploadStatus,
  PointOfInterest,
  useRemoveAttachmentsFromPoiMutation,
} from '@/__generated__/graphql';
import { Attachment } from '../types';
import { AllPointsOfInterestForInspectionDataLoaderDocument } from '@/__generated__/graphql';
import { MediaViewer } from '@/components/shared/MediaViewer';
import { RenderAttachment } from './DetailsTab/RenderAttachment';
import { getCloudfrontUrl } from '@/utils/cloudfront';

type Props = {
  selectedPoiInfo: PointOfInterest;
  attachments: Attachment[];
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
  setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AttachmentsViewer = ({
  attachments,
  setAttachments,
  selectedPoiInfo,
  setIsUpdating,
}: Props) => {
  const [removeAttachmentsFromPoi] = useRemoveAttachmentsFromPoiMutation();

  useEffect(() => {
    (async function getImageURLs() {
      if (selectedPoiInfo.files && selectedPoiInfo.files.length > 0) {
        const fetchedImages = await Promise.all(
          selectedPoiInfo.files
            .filter(
              (file) =>
                file.uploadStatus &&
                [FileUploadStatus.Success, FileUploadStatus.Inprogress].includes(file.uploadStatus)
            )
            .map(async ({ fileName, resourcePath, type, id, uploadStatus }) => {
              const poiAttachmentUrl = getCloudfrontUrl(resourcePath);
              const isImage =
                type.includes('image/') ||
                [FileTypes.Jpeg, FileTypes.Png].includes(type as FileTypes);
              const base64 = isImage ? await urlToBase64(poiAttachmentUrl) : undefined;
              return {
                id: id || '',
                name: fileName,
                base64: base64,
                type,
                isNew: false,
                url: poiAttachmentUrl,
                uploadStatus: uploadStatus || FileUploadStatus.Success,
              };
            })
        );
        setAttachments(() => fetchedImages);
      }
    })();
  }, [selectedPoiInfo.files, setAttachments]);

  const onUploadComplete = (index: number, file: Attachment) => {
    if (file.isNew && file.resourcePath) {
      const poiAttachmentUrl = getCloudfrontUrl(file.resourcePath);
      setAttachments((previous) => {
        const newAttachments = [...previous];
        newAttachments[index] = {
          ...newAttachments[index],
          url: poiAttachmentUrl,
          isNew: false,
          uploadStatus: FileUploadStatus.Success,
        };
        return newAttachments;
      });
      if (setIsUpdating) setIsUpdating(false);
    }
  };

  const handleDownloadAttachment = (attachmentIndex: number) => {
    const currentAttachment = attachments[attachmentIndex];
    if (!currentAttachment.url) return;
    downloadFile(currentAttachment.url, currentAttachment.name);
  };

  const handleRemoveAttachment = (attachmentIndex: number) => {
    const currentAttachment = attachments[attachmentIndex];
    if (!currentAttachment.id) return;
    removeAttachmentsFromPoi({
      variables: {
        input: {
          pointOfInterestId: selectedPoiInfo.id,
          fileIds: [currentAttachment.id],
        },
      },
      refetchQueries: [AllPointsOfInterestForInspectionDataLoaderDocument],
    });
    setAttachments(attachments.filter((attachment) => attachment.id !== currentAttachment.id));
  };

  return (
    <Grid container spacing={{ xl: 3, md: 2 }} px={2}>
      <MediaViewer
        mediaFiles={attachments}
        handleDownloadAttachment={handleDownloadAttachment}
        handleRemoveAttachment={handleRemoveAttachment}
      >
        {attachments.map((file, index) => (
          <Grid item lg={4} sm={6} key={file.id}>
            <RenderAttachment
              selectedAttachment={file}
              selectedFileIndex={index}
              showRemoveIcon={false}
              enableUpload={true}
              onUploadComplete={onUploadComplete}
              pointOfInterestId={selectedPoiInfo.id}
            />
          </Grid>
        ))}
      </MediaViewer>
    </Grid>
  );
};
