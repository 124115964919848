import { CircularProgress, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import EditIcon from '@mui/icons-material/Edit';
import { useHavePermission } from '@/hooks';
import { PointOfInterest } from '@/__generated__/graphql';
import { MediaButton, MediaButtonLabel, tooltipStyles } from './styles';
import { ArrowUpIcon } from '@/assets/icons/ArrowUpIcon';
import { ScreenshotIcon } from '@/assets/icons/ScreenshotIcon';
import UploadAttachmentsDialog from './UploadAttachmentsDialog';
import { AttachmentsViewer } from './AttachmentsViewer';
import { Permissions, ScreenshotLocationType } from '@/types';
import { Attachment } from '../types';
import { abyssColors } from '@/theme/colors';

type Props = {
  selectedPoiInfo: PointOfInterest;
  isUpdating: boolean;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  handleCaptureView: (location: ScreenshotLocationType) => void;
};

export const MediaUploadsAndViewer = ({
  selectedPoiInfo,
  isUpdating,
  setIsUpdating,
  handleCaptureView,
}: Props) => {
  const [openAttachmentsDialog, setOpenAttachmentsDialog] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [remediationNote, setRemediationNote] = useState<string | undefined | null>(
    selectedPoiInfo.remediationNote
  );

  const router = useRouter();

  const isAtInsightsPage = useMemo(() => router.pathname.includes('insights'), [router.pathname]);

  const isAddMode = selectedPoiInfo.files && selectedPoiInfo.files.length === 0;
  const dialogTitle = isAddMode ? 'Add Note' : 'Edit Note';
  const buttonText = isAddMode ? 'Add' : 'Update';
  const handleDialogToggle = () => setOpenAttachmentsDialog(!openAttachmentsDialog);
  const userCanUploadMedia = useHavePermission(Permissions.CREATE_UPLOAD_MEDIA);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
        <Typography variant="h6"> Note</Typography>
        {!isAddMode && userCanUploadMedia && (
          <IconButton onClick={handleDialogToggle}>
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      <Typography variant="caption">{remediationNote ?? ''}</Typography>
      <Stack direction="row" alignItems="start" gap={1.5} marginY={2}>
        {userCanUploadMedia && (
          <MediaButton disabled={isUpdating} onClick={handleDialogToggle} variant="outlined">
            {isUpdating ? <CircularProgress size={12} /> : <ArrowUpIcon />}
            <MediaButtonLabel>{isUpdating ? 'Uploading' : 'Upload'}</MediaButtonLabel>
          </MediaButton>
        )}
        {isAtInsightsPage ? (
          <Tooltip
            title="Only available in 3D viewer tab"
            disableHoverListener={false}
            arrow
            placement="top"
            PopperProps={{ sx: tooltipStyles }}
          >
            <span>
              <MediaButton variant="outlined" disabled>
                <ScreenshotIcon style={{ opacity: 0.5 }} />
                <MediaButtonLabel sx={{ color: abyssColors.neutral[400] }}>
                  Capture View
                </MediaButtonLabel>
              </MediaButton>
            </span>
          </Tooltip>
        ) : (
          <MediaButton
            variant="outlined"
            onClick={() => handleCaptureView('attachments')}
            disabled={isUpdating}
          >
            {isUpdating ? <CircularProgress size={12} /> : <ScreenshotIcon />}
            <MediaButtonLabel>Capture View</MediaButtonLabel>
          </MediaButton>
        )}
      </Stack>
      <AttachmentsViewer
        attachments={attachments}
        setAttachments={setAttachments}
        selectedPoiInfo={selectedPoiInfo}
        setIsUpdating={setIsUpdating}
      />
      <UploadAttachmentsDialog
        openDialog={openAttachmentsDialog}
        setOpenDialog={setOpenAttachmentsDialog}
        pointOfInterest={selectedPoiInfo}
        attachments={[...attachments]}
        dialogTitle={dialogTitle}
        buttonText={buttonText}
        setAttachments={setAttachments}
        setIsUpdating={setIsUpdating}
        setRemediationNote={setRemediationNote}
      />
    </>
  );
};
