import {
  VisibilityBoxMode,
  VisibilityBoxProps,
  ControlsType,
  TransformMode,
} from '@abyss/3d-viewer';

import {
  CorrosionLevel,
  PoiAccessibilityEnum,
  PoiStatus,
  ReportType,
  UnitsEnum,
} from '@/__generated__/graphql';
import {
  Colors,
  UserRoles,
  LabellingColors,
  CuboidTransformAction,
  ConversionField,
} from '@/types';
import { ReactElement } from 'react';

import { QuestionMark } from '@mui/icons-material';
import { abyssColors } from '../theme/colors';
import {
  BlisterIcon,
  CupsIcon,
  DeadlegIcon,
  HotboltingIcon,
  MeasurementToolIcon,
  OtherIcon,
  PaintDamageIcon,
  PitIcon,
  ReplayIcon,
  SubseaIcon,
} from './AnnotationIcons';
import { enumToString } from '@/utils/string';

export const PICKABLE_COLOR = 0x000000ff;
export const UNPICKABLE_COLOR = 0x0;
export const TRANSPARENT_COLOR = 0xffffff00;
export const NONSELECTED_DEFECT_COLOR = 0x6d6e6b30;
export const DEFAULT_COLORS: Colors = {
  colors: [
    0x48bf8eff, 0x194f46ff, 0xbadaddff, 0x3a508aff, 0xa369dbff, 0x6895bcff, 0x621da6ff, 0xfb5de7ff,
  ],
  selectedColour: 0xead624ff,
  noAssemblyColour: 0xddddddff,
  outOfScopeColour: 0x000000ff,
  highlightReviewedPartColour: 0x708090ff,
  segmentsFillColour: 0xffff00,
  segmentsLineColour: 0xffff00,
  foundAssemblyColour: 0x00c19cff,
  hoveredAssemblyColour: 0xf3fff8ff,
};

export const NO_TAG_ASSEMBLY_NAME = 'unlabelled';

export const DELETED_TAG_ASSEMBLY_NAME = 'deleted';

// This constant is used for display purposes in the equipment tag text field
export const NO_TAG_ASSEMBLY_DISPLAY_NAME = 'Untagged';

export const CORROSION_LEVEL_ORDER: { [key in CorrosionLevel]: number } = {
  CLEAN: 0,
  COATING_BREAKDOWN: 1,
  LIGHT: 2,
  MODERATE: 3,
  HEAVY: 4,
};

export const ALARM_LEVEL_TO_DISPLAY_TITLE: { [alarmLevel: string]: string } = {
  NONE: 'No alarm level',
  LEVEL_1: 'Alarm level 1',
  LEVEL_2: 'Alarm level 2',
  BORDERLINE_LEVEL_1_OR_2: 'Alarm level 1 or 2',
  LEVEL_3: 'Alarm level 3',
};

// Colour of a viewpoint that has been marked as analysed (MI services)
export const ANALYSED_VIEWPOINT_COLOR = 0x3d903f;
// Default defect colors configs

export const DEFAULT_VISIBILITY_BOX: VisibilityBoxProps = {
  min: [10, 10, -10],
  max: [80, 80, 30],
  step: [0.25, 0.25, 0.1],
  mode: VisibilityBoxMode.Disabled,
};

export const DEFAULT_LABELLING_COLORS: LabellingColors = {
  highlightColor: 0xffee20ff,
  deselectColor: 0xffffffff,
  defaultColor: 0x808080ff,
};

export const INPUT_ADORMENT_ICON_MAP: { [key: string]: ReactElement<'svg'> } = {
  MEASUREMENT_TOOL: <MeasurementToolIcon />,
  RELOAD: <ReplayIcon />,
};

export const POI_DETAILS_FALLBACK = [
  {
    name: 'PAINT_DAMAGE',
    iconColor: '4B7DC7',
    accentColor: '#60A5FA',
    icon: <PaintDamageIcon sx={{ color: '#fff' }} />,
  },
  {
    name: 'BLISTER',
    iconColor: 'C74C4B',
    accentColor: '#C084FC',
    icon: <BlisterIcon sx={{ color: '#fff' }} />,
  },
  {
    name: 'PIT',
    iconColor: 'CA8A04',
    accentColor: '#A78BFA',
    icon: <PitIcon sx={{ color: '#fff' }} />,
  },
  {
    name: 'HOTBOLTING',
    iconColor: '4BB1C7',
    accentColor: '#818CF8',
    icon: <HotboltingIcon sx={{ color: '#fff' }} />,
    widgetIcon: <HotboltingIcon />,
  },
  {
    name: 'CUPS',
    iconColor: 'C394FF',
    accentColor: '#22D3EE',
    icon: <CupsIcon sx={{ color: '#fff' }} />,
  },
  {
    name: 'SUBSEA',
    icon: <SubseaIcon sx={{ color: '#fff' }} />,
    widgetIcon: <SubseaIcon />,
    iconColor: '4B7554',
    accentColor: '#A4F0FC',
  },
  {
    name: 'OTHER',
    iconColor: '86B1BB',
    accentColor: '#8ECAE6',
    icon: <OtherIcon sx={{ color: '#fff' }} />,
  },
  {
    name: 'DEADLEGS',
    iconColor: 'A2C74B',
    accentColor: '#38BDF8',
    icon: <DeadlegIcon sx={{ color: '#fff' }} />,
    widgetIcon: <DeadlegIcon />,
  },
];

export const POI_DETAILS_DEFAULT = {
  name: 'DEFAULT',
  uncertainIconColor: 'CCC',
  iconColor: 'C74C4B',
  accentColor: '#000',
  icon: <QuestionMark sx={{ color: '#888' }} />,
};

export const CUBE_PADDING_Y = 350;
export const CUBE_PADDING_X = 820;

export const DEFAULT_CUBE_POSITION: [number, number, number] = [CUBE_PADDING_X, CUBE_PADDING_Y, 0];
export const FALLBACK_INSPECTION_IMAGE = '/assets/oil-rig-silhouette.jpg';
export const POI_IS_BLISTER_NAME = 'blistermeasurementdisabled';

export const SERVICE_GROUPS_COLORS: string[] = [
  '#fcd980',
  '#6ad983',
  '#668ff7',
  '#b986f9',
  '#d5dce4',
];

export const MATERIAL_COLORS: string[] = [
  ...SERVICE_GROUPS_COLORS,
  '#ff9e6a',
  '#78d3a1',
  '#7a92f6',
  '#d88ff9',
  '#e4e1d7',
  '#f3ca75',
  '#6bd1e7',
  '#a890f3',
];

export const PIE_CHART_COLORS = ['#F44336', '#FF9100', '#FDD835', '#668FF7', '#8454EC', '#B2BDCB'];

export const QUERY_DRAWER_WIDTH = 350;
// background color for the query drawer
export const QUERY_DRAWER_COLOR = '#F7F8FC';

export const TAGGING_GUIDE =
  'https://abyss-solutions.atlassian.net/wiki/spaces/PD/pages/2576024566/Fabric+tagging+guide';
export const USAGE_GUIDE = 'https://fabricv2-documentation.scrollhelp.site/v2/';
export const REPORT_A_BUG =
  'https://abyss-solutions.atlassian.net/servicedesk/customer/portal/3/group/8/create/103';

// extra range added to visibility box to show all relevant sphericals
// for TLS scans points cropped at 7 meters, so [10,10,10] would be enough for TLS
export const SPHERICAL_IMAGE_VISIBILITY_RANGE = [1000, 1000, 1000] as [
  x: number,
  y: number,
  z: number,
];

// allows to show deck levels upto 8 meter (z-axis) from assembly bbox
export const FLOOR_LEVELS_BBOX_PADDING = [1, 1, 8] as [x: number, y: number, z: number];

export const EXTERNAL_ROLES: Array<UserRoles> = ['generic-engineer', 'client'];

// to be replaced by dynamic property in the database.
// This is a list of possible values for corrosion groupName on defect legend on structure
export const CORROSION_GROUPNAMES = ['CORROSION', 'Substrate Condition'];
export const CORROSION_GROUPKEYS = ['defect', 'corrosion', 'substrate_condition'];
export const PLATFORM_EXPORT_CORROSION_GROUPKEY = 'coating_condition';

export const SystemTestStatusColor = {
  Poor: '#9F230D',
  Weak: '#9B661A',
  Good: '#357F70',
  'N/A': '#8A8A8A',
};
export const SystemTestStatusBgColor = {
  Poor: '#E9B1B1',
  Weak: '#E8D9B3',
  Good: '#C0E2CA',
  'N/A': '#CCCCCC',
};

export const coatingConditionLegend = {
  Ri5: '#EF4444',
  Ri4: '#F97316',
  Ri3: '#F59E0B',
  Ri2: '#FBBF24',
  Ri1: abyssColors.warning[300],
  Ri0: '#FEF9C3',
  Heavy: '#EF4444',
  Moderate: '#F59E0B',
  Light: abyssColors.warning[300],
  Clean: '#87F9AE',
  Area: '#E9E9E9',
};

export const UNCERTAIN_PROFILE_LABEL = 'Uncertain profile';
export const UNCERTAIN_PROFILE_SHORT_LABEL = 'Uncertain';

export const NOT_APPLICABLE_LABEL = 'N/A';
export const BLANK_SPACE = '';

export const SUBSTRATE_CONDITION = {
  HEAVY: 'Heavy',
  MODERATE: 'Moderate',
  LIGHT: 'Light',
  CLEAN: 'Clean',
};

export const REPORT_EQUIPMENT_HEIGHT = 48;
export const REPORT_POI_HEIGHT = 18;
export const REPORT_COLUMN_HEIGHT = 1000;

export const PIT = 'Pit';
export const BLISTER = 'Blister';
export const BLISTER_HEIGHT_LABEL = 'Maximum Blister Height';
export const PIT_DEPTH_LABEL = 'Maximum Pit Depth';
export const ESTIMATED_BLISTER_HEIGHT_LABEL = 'Estimated Blister Height';
export const ESTIMATED_PIT_DEPTH_LABEL = 'Estimated Pit Depth';

export const PRE_INSPECTION_LABEL = 'Pre inspection data';
export const POST_INSPECTION_LABEL = 'Post inspection data';
export const ALL_POIS_TAB_LABEL = 'All';

export const PIT_TYPE = 'PIT';
export const BLISTER_TYPE = 'BLISTER';

export const CUBOID_CONTROL_MODES: Record<CuboidTransformAction, number[]> = {
  Resize: [ControlsType.AlignedBox],
  SymmetricalResize: [ControlsType.Transform, TransformMode.Scale],
  Move: [ControlsType.Transform, TransformMode.Translate],
  Rotate: [ControlsType.Transform, TransformMode.Rotate],
};

// Risk Assessment Matrices
export const yLabels = ['I', 'II', 'III', 'IV'];
export const xLabels = ['A', 'B', 'C', 'D', 'E'];
export const businessLossKey = 'risk_unmitigated_business_loss';
export const sheKey = 'risk_unmitigated_she_actual_value';

export const DYNAMIC_POINT_SIZE_SCALE = 1.5;

export const defaultThumbnailConfig = {
  viewHeightMeters: 1,
  width: 512,
  height: 384,
};

export const UPDATE_LOCATION_LABEL = 'Location in 3D';

export const PAINT_BLOCK_LABEL = 'Paint Block';

export const CSV_TEMPLATE_FREE_TEXT_LABEL = 'Free text';

export const POI_CSV_TEMPLATE_COMMON_HEADERS: Array<{
  name: 'Name' | 'Component' | 'Paint Block' | 'Accessibility' | 'Status' | 'Due Date' | 'Comment';
  type: 'free-text' | 'database-entry' | 'select' | 'date';
  helperText: string;
  required: boolean;
  options?: string[];
}> = [
  { name: 'Name', type: 'free-text', helperText: CSV_TEMPLATE_FREE_TEXT_LABEL, required: true },
  {
    name: 'Component',
    type: 'database-entry',
    helperText: 'Leave empty if not found in the Fabric database',
    required: false,
  },
  {
    name: 'Paint Block',
    type: 'database-entry',
    helperText: 'Leave empty if not found in the Fabric database',
    required: false,
  },
  {
    name: 'Accessibility',
    type: 'free-text',
    helperText: CSV_TEMPLATE_FREE_TEXT_LABEL,
    required: false,

    options: Object.entries(PoiAccessibilityEnum).map(([, value]) =>
      enumToString(value)
    ) as string[],
  },
  {
    name: 'Status',
    type: 'select',
    helperText: CSV_TEMPLATE_FREE_TEXT_LABEL,
    required: false,
    options: Object.entries(PoiStatus).map(([, value]) => enumToString(value)) as string[],
  },
  { name: 'Due Date', type: 'date', helperText: '01/Jan/2024', required: false },
  { name: 'Comment', type: 'free-text', helperText: CSV_TEMPLATE_FREE_TEXT_LABEL, required: false },
] as const;

export const SUPPORT_ERROR_MESSAGE =
  'An unknown error occurred. Please contact support if this persists.';

export const CSV_VALIDATION_ERROR_CODE = 'CSV_VALIDATION_ERROR';

export const LINE_FIXING_CSV_HEADERS = ['TAGS IN FABRIC', 'ACTUAL TAGS', 'SUPER CATEGORY'] as const;
export const FIXABLE_TAGS_TABLE_HEADERS = [
  'INITIAL TAGS',
  'REPLACED TAGS (CURRENT)',
  'SUPER CATEGORY',
] as const;

// 04/Apr/2024
export const COMMON_DATE_FORMAT = 'dd/MMM/yyyy';

export const PoiReportEnumFormattingFilter: Record<ReportType, string> = {
  [ReportType.FmWorkpack]: 'FM Workpack',
  [ReportType.MiWorkpack]: 'MI Workpack',
  [ReportType.Inspection]: 'Inspection',
  [ReportType.Maintenance]: 'Maintenance',
};

export const PAINT_BLOCK_REPORT_DEFAULT_POI_TYPE = 'PAINT_DAMAGE';

export const UserTypes = {
  Local: 'Email invite',
  SSO: 'SSO user',
  Other: 'Other',
};

export const defaultPaginationSize = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '100', value: 100 },
];

export const SALES_CONTACT_EMAIL = 'n.povloski@abysssolutions.com.au';

export enum BulkPipeSpecUploadCsvHeadersEnum {
  ASSET_TAG = 'Asset Tag',
  MATERIAL = 'Material',
  NOMINAL_THICKNESS = 'Nominal Thickness',
  PIPE_SCHEDULE = 'Pipe Schedule',
  NOMINAL_CORROSION_ALLOWANCE = 'Nominal Corrosion Allowance',
}

export enum BulkUploadServiceGroupCsvHeadersEnum {
  SERVICE = 'Service',
  GROUP = 'Group',
}

export const MAX_BLISTER_HEIGHT_TEMPLATE_FIELD_KEY = 'governing_max_blister_height';
export const UNCERTAIN_REASON_FIELD_NAME = 'Uncertain Reason';

export const RESTORED_VERSION = 'Version Restore';

export const ALARM_LEVEL_MANUALLY_SET_TEMPLATE_KEY = 'recommendation_alarm_level_manually_set';
export const ALARM_LEVEL_TEMPLATE_KEY = 'recommendation_alarm_level';
export enum AlarmLevelSettingStatus {
  AUTO_GENERATED = 'Auto-generated',
  MANUALLY_ADDED = 'Manually added',
}

export const conversionFields: Record<string, ConversionField> = {
  size: {
    isPercentage: false,
  },
  nominalCorrosionAllowance: {
    unit: UnitsEnum.Millimeter,
    isPercentage: false,
  },
  nominalThickness: {
    unit: UnitsEnum.Millimeter,
    isPercentage: false,
  },
  governing_max_blister_height: {
    unit: UnitsEnum.Meter,
    isPercentage: false,
  },
  governing_wall_loss: {
    unit: UnitsEnum.Millimeter,
    isPercentage: true,
  },
  governing_wall_loss_percentage: {
    isPercentage: true,
  },
  governing_residual_corrosion_allowance: {
    unit: UnitsEnum.Millimeter,
    isPercentage: false,
  },
  governing_residual_thickness: {
    unit: UnitsEnum.Millimeter,
    isPercentage: false,
  },
  governing_residual_thickness_percentage: {
    isPercentage: true,
  },
  maxHeight: {
    unit: UnitsEnum.Millimeter,
    isPercentage: false,
  },
  estimatedWallLoss: {
    unit: UnitsEnum.Millimeter,
    isPercentage: false,
  },
  residualCorrosionAllowance: {
    unit: UnitsEnum.Millimeter,
    isPercentage: false,
  },
  residualThickness: {
    unit: UnitsEnum.Millimeter,
    isPercentage: false,
  },
};
