import { Stack, Switch, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useCallback } from 'react';
import * as state from '@/components/Analysis/state';
import { primary } from '@/theme/colors';

export const NonSelectedToggle = () => {
  const [isEnabled, setIsEnabled] = useRecoilState(state.nonSelectedDefectsIsEnabled);

  const handleCheckboxChange = useCallback(() => {
    setIsEnabled((current) => !current);
  }, [setIsEnabled]);

  return (
    <Stack direction="row" spacing={2} mt={1} alignItems="center" justifyContent="space-between">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={'space-between'}
        width={'100%'}
        className="defect-toggle-container"
      >
        <Typography fontSize={14}>Show Unselected Area</Typography>
        <Switch
          checked={isEnabled}
          onChange={handleCheckboxChange}
          className="defect-toggle"
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: primary.LightBlue,
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: primary.LightBlue,
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
