import { Button, Stack, Typography, styled as muiStyled } from '@mui/material';
import { abyssColors } from '@/theme/colors';

export const MediaButton = muiStyled(Button)(() => ({
  padding: '4px 8px',
  height: '26px',
  borderRadius: '4px',
  fontSize: '1.2rem',
  background: abyssColors.neutral[50],
  color: abyssColors.primary[500],
  fontWeight: 600,
  boxShadow: 'none',
  textTransform: 'none',
  border: `1px solid ${abyssColors.primary[500]} !important`,
}));

export const MediaButtonLabel = muiStyled(Typography)(() => ({
  color: abyssColors.primary[500],
  fontWeight: 600,
  fontSize: '1.2rem',
  margin: '0 6px',
}));

export const PanelCard = muiStyled(Stack)(() => ({
  marginTop: '0.8rem',
  marginBottom: '3.2rem',
  alignItems: 'center',
  width: '100%',
  padding: '1.6rem',
  backgroundColor: abyssColors.neutral[50],
  borderRadius: '0.4rem',
  overflow: 'hidden',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  '& img': {
    border: `1px solid ${abyssColors.primary[100]}`,
  },
}));

type ButtonProps = {
  contained?: boolean;
  compact?: boolean;
};

export const ActionButton = muiStyled(Button)(({ contained, compact }: ButtonProps) => ({
  padding: compact ? '0.8rem 1.4rem' : '0.8rem 1.8rem',
  height: '2.6rem',
  borderRadius: '0.4rem',
  fontSize: '1.2rem',
  background: contained ? abyssColors.primary[500] : abyssColors.neutral[50],
  color: contained ? abyssColors.neutral[50] : abyssColors.primary[500],
  boxShadow: 'none',
  textTransform: 'none',
  width: 'fit-content',
  lineHeight: 'normal',
  marginLeft: '0px !important',
  gap: '0.6rem',
  '&:disabled': {
    background: contained ? abyssColors.neutral[300] : abyssColors.neutral[50],
    color: contained ? abyssColors.neutral[50] : abyssColors.primary[300],
  },
  '&:hover': {
    background: contained ? abyssColors.primary[400] : abyssColors.neutral[50],
  },
}));

export const tooltipStyles = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#000000B8',
    color: abyssColors.primary[50],
    opacity: 1,
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    py: '10px',
    height: '28px',
    fontWeight: 500,
  },
  '& .MuiTooltip-arrow': {
    color: '#000000B8',
  },
};
