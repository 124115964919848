/* eslint-disable unicorn/prevent-abbreviations */
import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import * as poiState from '../state';
import { useRouter } from 'next/router';

/**
 * A custom React hook that manages the effect of triggering a POI location update via routing.
 *
 * This hook performs the following tasks:
 * 1. Checks if the `isUpadtingPoiLocation` query parameter is present in the router's query.
 * 2. Validates the presence of `selectedPointOfInterestId`, and `selectedAssemblyId`, since you can't update the location of a POI if it doesn't exist.
 * 3. If all conditions are met, triggers the POI (location) update state
 * 4. Updates the router's query parameters to remove the `isUpadtingPoiLocation` parameter, so that the effect doesn't run again.
 *
 * This hook is used to trigger location updating, when the user had pressed the "Update Location" button. from another page (i.e Analysis)
 *
 * @returns {void} This hook doesn't return any value, it only produces side effects.
 */
export const useTriggerPoiLocationUpdateEffect = () => {
  const selectedPointOfInterestId = useRecoilValue(poiState.selectedPointOfInterestId);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const router = useRouter();

  const { isUpadtingPoiLocation, ...rest } = router?.query || {};


  const setEditPointOfInterest = useSetRecoilState(poiState.editPointOfInterest);

  const shouldUpdate = useMemo(() => {
    if (!isUpadtingPoiLocation) return false;
    if (!selectedPointOfInterestId) return false;
    if (!selectedAssemblyId) return false;
    return true;
  }, [isUpadtingPoiLocation, selectedPointOfInterestId, selectedAssemblyId]);

  useEffect(() => {
    if (shouldUpdate) {
      setEditPointOfInterest((previous) => ({
        ...previous,
        formState: 'Update-Location',
        state: 'WaitingForPoint',
      }));
      router.replace({
        query: { ...rest },
      });
    }
  }, [shouldUpdate, setEditPointOfInterest, router, rest]);
};
