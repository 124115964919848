import { Maybe } from '@/__generated__/graphql';
import { getCloudfrontUrl } from '@/utils/cloudfront';

type PoiImageProps = {
  viewpointImage?: Maybe<string>;
  viewerImage?: Maybe<string>;
};

const imageStyles = {
  width: '100%',
  maxHeight: '20rem',
  backgroundColor: '#283238',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  marginTop: 24,
};

export const PoiImage = ({ viewpointImage, viewerImage }: PoiImageProps) => {
  return viewpointImage ? (
    <img
      crossOrigin="use-credentials"
      src={getCloudfrontUrl(viewpointImage)}
      alt="Viewpoint"
      style={imageStyles}
    />
  ) : viewerImage ? (
    <img
      crossOrigin="use-credentials"
      src={getCloudfrontUrl(viewerImage)}
      alt="Viewer"
      style={imageStyles}
    />
  ) : (
    <></>
  );
};
