var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fabric-client-react@2.205.0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const API_URL = process.env.API_URL || process.env.NEXT_PUBLIC_API_URL;
const CLIENT_URL = window.location.origin;

const SENTRY_ENVIRONMENT =
  process.env.SENTRY_ENVIRONMENT ||
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT ||
  process.env.NODE_ENV;
if (process.env.SENTRY_ENVIRONMENT) {
  Sentry.addTracingExtensions();
  Sentry.init({
    dsn: SENTRY_DSN || 'https://cd23934b43f9727a482a0c7409f5af22@sentry.abyssfabric.co/3',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: SENTRY_ENVIRONMENT || 'development',
    // This sets the sample rate to be 33%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [API_URL, CLIENT_URL],
      }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
    ],
  });
}
