import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Divider, Fade, LinearProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import * as localState from '@/components/Analysis/state';
import * as poiState from '@/components/Analysis/modules/pointOfInterest/state';
import { Graph, Point } from './Graph';
import {
  MeasureBlisterQuery,
  PointOfInterest,
  useMeasureBlisterLazyQuery,
} from '@/__generated__/graphql';
import { MeasurementToolIcon } from '@/constants/AnnotationIcons';
import { PIT, BLISTER, PIT_TYPE } from '@/constants';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';
import { UncertainFieldHook } from '../../PointOfInterestTab/SelectedPoiContent/hooks/useUncertainField';
import { UncertainReasonField } from '../../PointOfInterestTab/SelectedPoiContent/Forms/UncertainReasonField';
import MarkAsUncertainIcon from '@mui/icons-material/HelpOutline';
import { abyssColors } from '@/theme/colors';

export type PotentialBlisterDataForMutation = Partial<PointOfInterest['blister']>;
export type BlisterDataForMutation = PointOfInterest['blister'];
type Props = {
  poiToAdd?: PointOfInterest;
  onMeasure: (blisterData: MeasureBlisterQuery | undefined) => void;
  onSelectPoint: (point: Point) => void;
  pointSelectMessage: boolean;
  clearBlisterData: () => void;
  measureOnLoad?: boolean;
  uncertainField: UncertainFieldHook;
};

export const BlisterMeasurement = ({
  poiToAdd,
  onMeasure,
  onSelectPoint,
  pointSelectMessage,
  clearBlisterData,
  measureOnLoad,
  uncertainField,
}: Props) => {
  const selectedSpherical = useRecoilValue(localState.selectedSpherical);
  const manualBlisterHeightExists = useRecoilValue(poiState.manualBlisterHeightExists);
  const [points, setPoints] = useState<Point[] | undefined>();
  const [uncertainReasonDialogOpen, setUncertainReasonDialogOpen] = useState(false);

  const [haveMeasured, setHaveMeasured] = useState(false);
  const [measureBlisterQuery, { data, loading }] = useMeasureBlisterLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const handleMeasure = useCallback(async () => {
    const queryInput = {
      locationId: selectedSpherical?.id,
      blisterMeasurementPoints: {
        centerPoint: {
          x: poiToAdd?.blister?.centerPoint.bearing!,
          y: poiToAdd?.blister?.centerPoint.elevation!,
        },
        edgePoint: {
          x: poiToAdd?.blister?.radiusPoint.bearing!,
          y: poiToAdd?.blister?.radiusPoint.elevation!,
        },
      },
    };
    await measureBlisterQuery({
      variables: queryInput,
    })
      .then(async (result) => {
        if (!manualBlisterHeightExists) onMeasure(result?.data);
      })
      .catch(() => {
        setHaveMeasured(false);
      });
    setHaveMeasured(true);
  }, [
    selectedSpherical?.id,
    poiToAdd?.blister?.centerPoint.bearing,
    poiToAdd?.blister?.centerPoint.elevation,
    poiToAdd?.blister?.radiusPoint.bearing,
    poiToAdd?.blister?.radiusPoint.elevation,
    measureBlisterQuery,
    onMeasure,
    manualBlisterHeightExists,
  ]);

  useEffect(() => {
    if (measureOnLoad) {
      handleMeasure();
    }
  }, [measureOnLoad, handleMeasure]);

  const handleClearMeasurement = useCallback(() => {
    setHaveMeasured(false);
    if (clearBlisterData) clearBlisterData();
  }, [clearBlisterData]);

  useEffect(() => {
    if (!data?.fetchBlisterHeightMeasurement?.blister) return;
    setPoints(data?.fetchBlisterHeightMeasurement!.blister.blisterProfilePlotPoints || []);
  }, [
    data?.fetchBlisterHeightMeasurement,
    data?.fetchBlisterHeightMeasurement?.blister,
    setPoints,
  ]);

  const measurePoiButtonText = useMemo(() => {
    return poiToAdd?.blister?.estimatedHeight ? 'Regenerate Measurement' : 'Generate Measurement';
  }, [poiToAdd?.blister?.estimatedHeight]);

  const { doublePanelWidth, rightDrawerWidth } = useDrawerWidths();

  const handleMarkAsUncertain = () => {
    if (uncertainField.field) setUncertainReasonDialogOpen(true);
    else handleClearMeasurement();
  };

  const dialogProps = useMemo(
    () => ({
      open: uncertainReasonDialogOpen,
      handleClose: () => setUncertainReasonDialogOpen(false),
      handleClearMeasurement,
    }),
    [handleClearMeasurement, uncertainReasonDialogOpen]
  );

  return (
    <Box sx={{ padding: '2%', height: '100%' }}>
      <Stack direction="column" justifyContent="space-between">
        <>
          <Typography variant="body1" fontWeight={700} sx={{ mb: 2, mr: 'auto', width: '100%' }}>
            {poiToAdd?.type === PIT_TYPE ? PIT : BLISTER} profile
          </Typography>
          {!haveMeasured && !loading && (
            <Stack
              sx={{ height: 'calc(100vh / var(--zoom) - 200px)' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                onClick={handleMeasure}
                sx={{ mb: 3, height: '35px', borderRadius: '4px', textTransform: 'capitalize' }}
                size="small"
                startIcon={<MeasurementToolIcon />}
              >
                {measurePoiButtonText}
              </Button>
              <Typography variant="body2" sx={{ width: '100%', textAlign: 'center' }}>
                Adjust the {poiToAdd?.type === PIT_TYPE ? PIT.toLowerCase() : BLISTER.toLowerCase()}
                ’s centre and edge points in the image to generate more accurate measurement
              </Typography>
            </Stack>
          )}

          {loading ? (
            <Stack
              sx={{ height: 'calc(100vh / var(--zoom) - 200px)' }}
              direction="column"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src="/assets/abyss_fabric_mini_logo_black.svg"
                alt="Abyss Fabric"
                style={{ marginBottom: '36px' }}
              />
              <LinearProgress sx={{ width: '292px' }} />
              <Typography
                variant="body2"
                sx={{ width: '100%', textAlign: 'center', mt: 3.5, letterSpacing: 0 }}
              >
                Measuring {poiToAdd?.type === PIT_TYPE ? PIT.toLowerCase() : BLISTER.toLowerCase()}{' '}
                height. This may take a while.
              </Typography>
            </Stack>
          ) : (
            <>
              {points && haveMeasured && (
                <>
                  {pointSelectMessage && (
                    <Fade in>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          backgroundColor: '#f0f2f5',
                          padding: '10px 4px',
                          width: '100%',
                          borderRadius: '8px',
                          mb: 2,
                        }}
                      >
                        <InfoIcon sx={{ fontSize: '2rem' }} />
                        <Typography sx={{ color: 'black', fontSize: '1.2rem', fontWeight: 500 }}>
                          Click on the graph to manually set max value of the{' '}
                          {poiToAdd?.type === PIT_TYPE ? PIT.toLowerCase() : BLISTER.toLowerCase()}
                        </Typography>
                      </Stack>
                    </Fade>
                  )}
                  <Stack spacing={1.2} sx={{ mb: 5 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography sx={{ fontWeight: 500, fontSize: '1.4rem' }}>
                        3D point cloud
                      </Typography>
                      <Button
                        sx={{
                          p: '8px, 14px, 8px, 10px',
                          height: '26px',
                          borderRadius: '4px',
                          backgroundColor: `${abyssColors.error[600]} !important`,
                        }}
                        variant="contained"
                        startIcon={<MarkAsUncertainIcon sx={{ mr: 0 }} />}
                        onClick={handleMarkAsUncertain}
                      >
                        <Typography fontSize={12} fontWeight={600} lineHeight="18px">
                          Mark it uncertain
                        </Typography>
                      </Button>
                    </Stack>
                    <Stack sx={{ height: doublePanelWidth - rightDrawerWidth - 50 }}>
                      <Graph points={points} onSelectPoint={onSelectPoint} />
                    </Stack>
                    <Divider />
                  </Stack>
                </>
              )}

              {haveMeasured && (
                <Box sx={{ position: 'relative' }}>
                  <img
                    src="/assets/blister_graph_coming_soon.svg"
                    alt="coming soon"
                    style={{
                      width: '100%',
                      objectFit: 'contain',
                      marginBottom: '20px',
                    }}
                  />
                  <Box
                    sx={{
                      width: '100%',
                      textAlign: 'center',
                      position: 'absolute',
                      top: '50%',
                      opacity: 0.5,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500 }}>Coming Soon!</Typography>
                    <Typography variant="body2">
                      {`You'll soon be able to use a cross-sectional plane as well.`}
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
      </Stack>
      {uncertainReasonDialogOpen && (
        <UncertainReasonField uncertainField={uncertainField} dialog={dialogProps} />
      )}
    </Box>
  );
};
