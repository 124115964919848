import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useRecoilValue } from 'recoil';
import {
  AllPointOfInterestsForStructureQuery,
  useAllPointOfInterestsForStructureLazyQuery,
  useGetAllAssembliesLazyQuery,
} from '@/__generated__/graphql';
import { localiseMillimetreMeasurement } from '@/utils/unitSystem';
import * as state from '@/components/Analysis/state';
import { ALARM_LEVEL_TO_DISPLAY_TITLE } from '@/constants';
import { useHasAssemblyBeenTagged } from '@/components/Analysis/modules/pointCloud';
import { ButtonLabel, containedButtonStyle } from '@/theme';
import { DownloadIcon } from '@/assets/icons/DownloadIcon';

type Props = {
  structureId: string;
};

type PointOfInterestTypeByAssembly = {
  [key: string]: { cups: boolean; hotbolting: boolean; other: boolean };
};

const createPointOfInterestListByAssemblyId = (
  allPointOfInterests: AllPointOfInterestsForStructureQuery['allPointOfInterests']
) => {
  return allPointOfInterests.reduce((accumulator: PointOfInterestTypeByAssembly, poi) => {
    const assemblyId = poi.assembly.id;

    if (!accumulator[assemblyId]) {
      accumulator[assemblyId] = { cups: false, hotbolting: false, other: false };
    }

    switch (poi.type) {
      case 'CUPS':
        accumulator[assemblyId].cups = true;
        break;
      case 'HOTBOLTING':
        accumulator[assemblyId].hotbolting = true;
        break;
      case 'OTHER':
        accumulator[assemblyId].other = true;
        break;
      default:
        break;
    }

    return accumulator;
  }, {});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const replaceNullValues = (_: string, value: any) => {
  // eslint-disable-next-line eqeqeq
  return value == undefined ? '' : value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertToCsvString = (items: any[]) => {
  if (items.length === 0) {
    return '';
  }

  const headers = Object.keys(items[0]);

  return [
    headers.join(','),
    ...items.map((item) =>
      headers.map((fieldName) =>
        JSON.stringify(item[fieldName], replaceNullValues).replace(/\\"/g, '""')
      )
    ),
  ].join('\r\n');
};

const CSV_FILE_TYPE = 'text/csv;charset=utf-8';
const downloadCsv = (filename: string, csvString: string) => {
  // BOM support for special characters in Excel
  const byteOrderMark = '\uFEFF';

  const csvBlob = new Blob([byteOrderMark, csvString], {
    type: CSV_FILE_TYPE,
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(csvBlob);
  link.target = '_blank';
  link.download = filename;
  link.style.display = 'none';
  document.body.append(link);
  link.click();
  link.remove();
};

/**
 * Button to download all assets info as csv
 */
export const ExportAssetsButton = ({ structureId }: Props) => {
  // based on the current url of the analysis page
  const analysisUrl = document.URL;
  const [getAllAssemblies, { loading }] = useGetAllAssembliesLazyQuery({
    variables: {
      structureId,
    },
    fetchPolicy: 'network-only',
  });
  const [allPointsOfInterestForStructure, { loading: allPoiForStructureLoading }] =
    useAllPointOfInterestsForStructureLazyQuery({
      variables: {
        structureId,
      },
      fetchPolicy: 'network-only',
    });
  const unitSystem = useRecoilValue(state.unitSystem);
  const localiseUnit = (measurement: number | undefined | null) =>
    localiseMillimetreMeasurement(unitSystem, measurement);

  const { hasAssemblyBeenTagged } = useHasAssemblyBeenTagged();

  const exportAllAssets = async () => {
    const { data } = await getAllAssemblies();
    const { data: allPOI } = await allPointsOfInterestForStructure();

    if (data?.allAssemblies) {
      // data dictionary that will indicate if an assembly has cups and/or hotbolting
      // the elements are indexed by assemblyId, and will only exist if the assembly has cups or hotbolting
      const allPointOfInterests = createPointOfInterestListByAssemblyId(
        allPOI?.allPointOfInterests || []
      );

      const flattenedAssemblies = data.allAssemblies.reduce((accumulator, currentAssembly) => {
        const { id, tagName, service, recommendation, pipeSpec } = currentAssembly;

        if (hasAssemblyBeenTagged(id)) {
          const analysisRecommendation = recommendation?.analysisRecommendation;
          const governingValue = recommendation?.governingValue?.calculations;
          const resultToAdd = {
            Service: service?.code,
            Asset: tagName,
            'Analysis by': analysisRecommendation?.recommendationByUserName,
            Status: recommendation?.analysisStatus,
            URL: `=HYPERLINK("${analysisUrl}?assetId=${id}")`, // url to the viewer
            'Analysis comments': `${analysisRecommendation?.supportingComment || ''}${
              analysisRecommendation?.additionalComments ? ' - ' : ''
            }${analysisRecommendation?.additionalComments || ''}`,
            'Internal team comments': `${analysisRecommendation?.internalTeamComments || ''}`,
            'Governing value blister URL': governingValue
              ? `=HYPERLINK("${analysisUrl}?assetId=${id}&governingValue=true")`
              : '',
            'Max blister height': localiseUnit(governingValue?.height),
            Size: pipeSpec?.size,
            'Pipe spec': pipeSpec?.spec,
            Material: pipeSpec?.material,
            'Nominal corrosion allowance': localiseUnit(pipeSpec?.nominalCorrosionAllowance),
            'Pipe schedule': pipeSpec?.schedule,
            'Nominal thickness': localiseUnit(pipeSpec?.nominalThickness),
            'Est. wall loss': localiseUnit(governingValue?.estimatedWallLoss),
            'Est. wall loss %': governingValue?.estimatedWallLossPercentage,
            'Residual corrosion allowance': localiseUnit(
              governingValue?.residualCorrosionAllowance
            ),
            'Residual corrosion allowance %': governingValue?.residualCorrosionAllowancePercentage,
            'Residual thickness': localiseUnit(governingValue?.residualThickness),
            'Residual thickness %': localiseUnit(governingValue?.residualThicknessPercentage),
            'Alarm level':
              analysisRecommendation?.alarmLevel &&
              ALARM_LEVEL_TO_DISPLAY_TITLE[analysisRecommendation?.alarmLevel],
            'CUPS instances': `=HYPERLINK("${analysisUrl}?assetId=${id}", "${
              allPointOfInterests && allPointOfInterests[id]?.cups ? 'Yes' : 'No'
            } (see link)")`,
            'Hotbolting instances': `=HYPERLINK("${analysisUrl}?assetId=${id}", "${
              allPointOfInterests && allPointOfInterests[id]?.hotbolting ? 'Yes' : 'No'
            } (see link)")`,
            'Other instances': `=HYPERLINK("${analysisUrl}?assetId=${id}", "${
              allPointOfInterests && allPointOfInterests[id]?.other ? 'Yes' : 'No'
            } (see link)")`,
          };
          accumulator.push(resultToAdd);
        }
        return accumulator;
      }, [] as unknown[]);

      const csvString = convertToCsvString(flattenedAssemblies);
      const filename = `${data?.structure?.name}-analysis-${new Date().toISOString()}.csv`;
      downloadCsv(filename, csvString);
    }
  };

  return (
    <Button
      id="export-all-assets-button"
      variant="contained"
      onClick={exportAllAssets}
      sx={{
        ...containedButtonStyle,
        width: '100%',
        height: 44,
        fontWeight: 700,
        fontSize: '16px',
      }}
      disabled={loading || allPoiForStructureLoading}
      color="primary"
    >
      {loading || allPoiForStructureLoading ? (
        <CircularProgress sx={{ color: '#fff' }} size="2.4rem" />
      ) : (
        <>
          <DownloadIcon />
          <ButtonLabel>EXPORT ALL ASSETS</ButtonLabel>
        </>
      )}
    </Button>
  );
};
