import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { format } from 'date-fns';
import { useUser } from '@auth0/nextjs-auth0';
import * as poiState from '../../../state';
import { PoiImage } from '../../PoiImage';
import { FieldViewer } from '../FieldViewer';
import { useCreatedBy } from '@/hooks';
import { convertTimeStampToDate, formatEnumForDisplay, titleCase, formatPoiStatus } from '@/utils';
import { enumToString } from '@/utils/string';
import { useImageFromLocationId } from '@/components/Analysis/Viewer/hooks/useImageFromLocationId';
import {
  PointOfInterest,
  UnitSystemEnum,
} from '@/__generated__/graphql';
import * as state from '@/components/Analysis/state';
import { convertMetresToFeetDisplay, getMetresDisplay } from '@/utils/unitSystem';
import {
  BLISTER_TYPE,
  NOT_APPLICABLE_LABEL,
  PAINT_BLOCK_LABEL,
  PIT_TYPE,
  UPDATE_LOCATION_LABEL,
} from '@/constants';
import { MediaUploadsAndViewer } from './MediaUploadAndViewer';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
import { useAnalysisCuboidActions } from '@/components/Analysis/modules/cuboids/state/actions';
import { LinkedPois } from './LinkedPois';
import { MeasureAutoPointOfInterest } from './MeasureAutoPointOfInterest';
import { useTriggerPoiLocationUpdateEffect } from '../../../effects/useTriggerPoiLocationUpdateEffect';
import { abyssColors } from '@/theme/colors';
import { useSavePoiScreenshot } from '../../../createOrEdit/useSavePoiScreenshot';
import { ScreenshotLocationType } from '@/types';

const boxRootStyle = {
  px: '2.4rem',
  py: '3.2rem',

  '& > * + *': {
    marginTop: 2,
  },
  // enable scrolling
  overflowY: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
};

type ClickEvent = React.MouseEvent<HTMLDivElement, MouseEvent>;

type FieldsType = {
  label: string;
  value: string;
  onClick?: (event: undefined | ClickEvent) => void;
  valueTooltip?: string;
};

type Props = {
  selectedPoiInfo: PointOfInterest;
  selectedPoiInfoLoading?: boolean;
};

export const GeneralTab = ({ selectedPoiInfo, selectedPoiInfoLoading = false }: Props) => {
  const setEditPointOfInterest = useSetRecoilState(poiState.editPointOfInterest);
  const allViewpoints = useRecoilValue(state.structureLocations);
  const setCameraTarget = useSetRecoilState(state.cameraTarget);
  const setSelectedSpherical = useSetRecoilState(state.selectedSpherical);
  const setSelectedAssemblyId = useSetRecoilState(state.selectedAssemblyId);
  const setSelectedAssemblyName = useSetRecoilState(state.selectedAssemblyName);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const getSphericalFromId = useImageFromLocationId(allViewpoints);
  const unitConversionSystem = useRecoilValue(state.unitSystem);
  const allVoiTemplates = useRecoilValue(state.allVoiTemplates);

  const [isUpdating, setIsUpdating] = useState(false);
  const { handleSavePoiScreenshot } = useSavePoiScreenshot();

  const { startEditingPoiCuboids } = useAnalysisCuboidActions();

  const router = useRouter();
  const { user } = useUser();
  const navbarHeight = useNavbarHeight();

  const isAtInsightsPage = useMemo(() => {
    return router.pathname.includes('insights');
  }, [router.pathname]);

  // internal view external: they should be able to see external emails
  const createdByLabel = useCreatedBy({
    viewer: user,
    creator: selectedPoiInfo.createdByUser,
  });

  const poiSpherical = useMemo(() => {
    if (!selectedPoiInfo.location?.id) return;
    const spherical = getSphericalFromId(selectedPoiInfo.location.id);
    if (spherical?.name) return spherical;
  }, [getSphericalFromId, selectedPoiInfo.location]);

  const imageName = useMemo(() => {
    return poiSpherical?.name ?? NOT_APPLICABLE_LABEL;
  }, [poiSpherical]);

  const goToImageView = useCallback(
    (event: ClickEvent) => {
      const { position, name: sphericalName } = poiSpherical ?? {};
      if (!poiSpherical || !sphericalName) return;

      const { centerPoint3d, assembly, id: poiId } = selectedPoiInfo;
      const { x, y, z } = centerPoint3d || {};
      if (x === undefined || y === undefined || z === undefined) return;
      const lookAt = { x, y, z };
      setCameraTarget({ position, lookAt: [x, y, z] });
      setSelectedSpherical({ ...poiSpherical, lookAt });
      setSelectedAssemblyId(assembly.id);
      setSelectedAssemblyName(assembly.tagName);

      let url = `/analysis/${router.query.inspection}/viewer`;

      const isCtrlClick = event.ctrlKey || event.metaKey;
      if (isCtrlClick) {
        const lookAtParameters = `&look.x=${x}&look.y=${y}&look.z=${z}`;
        url += `?assembly=${assembly.tagName}&poi=${poiId}${lookAtParameters}&selectedSpherical=${sphericalName}`;
        window.open(url, '_blank');
      } else {
        if (isAtInsightsPage) router.push(url);
      }
    },
    [
      poiSpherical,
      selectedPoiInfo,
      setCameraTarget,
      setSelectedSpherical,
      setSelectedAssemblyId,
      setSelectedAssemblyName,
      router,
      isAtInsightsPage,
    ]
  );

  const selectedPoiHeight = useMemo(() => {
    const height = selectedPoiInfo.deckRelativeHeight;
    return unitConversionSystem === UnitSystemEnum.Imperial
      ? convertMetresToFeetDisplay(height)
      : getMetresDisplay(height);
  }, [selectedPoiInfo.deckRelativeHeight, unitConversionSystem]);

  const selectedPoiViewpointRelativeHeight = useMemo(() => {
    const height = selectedPoiInfo.viewpointRelativeHeight;
    return unitConversionSystem === UnitSystemEnum.Imperial
      ? convertMetresToFeetDisplay(height)
      : getMetresDisplay(height);
  }, [selectedPoiInfo.viewpointRelativeHeight, unitConversionSystem]);

  const handleUpdateLocation = useCallback(
    (event: ClickEvent | undefined) => {
      if (isAtInsightsPage) {
        if (!event) return;
        // The user is at insights page, redirect them to viewer
        // From there the `useTriggerPoiLocationUpdateEffect` will be triggered
        // this effect will be listening for the `isUpadtingPoiLocation` query param

        const isCtrlClick = event.ctrlKey || event.metaKey;
        if (isCtrlClick) {
          let url = `/analysis/${router.query.inspection}/viewer?assembly=${selectedPoiInfo.assembly.tagName}&poi=${selectedPoiInfo.id}&isUpadtingPoiLocation=true`;
          window.open(url, '_blank');
          return;
        } else {
          router.replace({
            pathname: `/analysis/${router.query.inspection}/viewer`,
            query: {
              isUpadtingPoiLocation: true,
            },
          });
        }

        return;
      }
      setEditPointOfInterest((previous) => ({
        ...previous,
        formState: 'Update-Location',
        state: 'WaitingForPoint',
      }));
    },
    [
      isAtInsightsPage,
      router,
      selectedPoiInfo.assembly.tagName,
      selectedPoiInfo.id,
      setEditPointOfInterest,
    ]
  );

  const generalFields = useMemo(() => {
    const convertedDueDate = convertTimeStampToDate(selectedPoiInfo.dueDate);
    const formattedDueDate = convertedDueDate
      ? format(convertedDueDate, 'dd/MMM/yyyy')
      : NOT_APPLICABLE_LABEL;

    const convertedCreatedAtData = convertTimeStampToDate(selectedPoiInfo.createdAt);
    const formattedCreatedAt = convertedCreatedAtData
      ? format(convertedCreatedAtData, 'dd/MMM/yyyy')
      : NOT_APPLICABLE_LABEL;

    const {
      poiId,
      name,
      paintRegion,
      assembly,
      status,
      workpackNumber,
      visibility,
      assignee,
      comment,
      accessibility,
      type,
      centerPoint3d,
    } = selectedPoiInfo;

    let poiHasLocation = true;
    const coordinates = centerPoint3d;

    // if no coordinates, POI was uploaded via CSV
    // hence, no location
    if (!coordinates) poiHasLocation = false;
    else if (coordinates?.x === 0 && coordinates?.y === 0 && coordinates?.z === 0)
      poiHasLocation = false;

    const fields: FieldsType[] = [
      {
        label: 'POI ID',
        value: poiId ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: 'Name',
        value: name ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: PAINT_BLOCK_LABEL,
        value: paintRegion?.name ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: 'Component',
        value: assembly?.tagName ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: 'Image Name',
        value: imageName,
        onClick: goToImageView as () => void,
        valueTooltip: imageName === NOT_APPLICABLE_LABEL ? '' : 'Ctrl + Click to open in new tab',
      },
      {
        label: 'Due Date',
        value: formattedDueDate,
      },
      {
        label: 'Status',
        value: status ? formatPoiStatus(status) : NOT_APPLICABLE_LABEL,
      },
      {
        label: 'Workpack No.',
        value: workpackNumber ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: 'Access Control',
        value: titleCase(visibility) ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: 'Assigned To',
        value: assignee ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: 'Created By',
        value: createdByLabel,
      },
      {
        label: 'Comment',
        value: comment ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: 'Accessibility',
        value: formatEnumForDisplay(accessibility) ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: 'Date Created',
        value: formattedCreatedAt,
      },
      {
        label: 'POI Height from Deck',
        value: selectedPoiHeight ?? NOT_APPLICABLE_LABEL,
      },
      {
        label: 'POI Height from Viewpoint',
        value: selectedPoiViewpointRelativeHeight ?? NOT_APPLICABLE_LABEL,
      },
    ];
    if (
      ![...allVoiTemplates.map(enumToString), BLISTER_TYPE, PIT_TYPE].includes(
        allVoiTemplates.includes(enumToString(type)) ? enumToString(type) : type
      )
    ) {
      fields.push({
        label: UPDATE_LOCATION_LABEL,
        value: poiHasLocation ? 'true' : 'false',
        onClick: handleUpdateLocation,
      });
    }

    return fields;
  }, [
    selectedPoiInfo,
    imageName,
    goToImageView,
    createdByLabel,
    selectedPoiHeight,
    selectedPoiViewpointRelativeHeight,
    allVoiTemplates,
    handleUpdateLocation,
  ]);

  const startGeneralFieldsEditing = useCallback(() => {
    setEditPointOfInterest((previous) => ({
      ...previous,
      pointOfInterest: selectedPoiInfo,
      formState: 'Update-General',
      state: 'WaitingForDetail',
    }));
    startEditingPoiCuboids(selectedPoiInfo);
  }, [selectedPoiInfo, setEditPointOfInterest, startEditingPoiCuboids]);

  const isVoi = selectedPoiInfo?.type
    ? allVoiTemplates.includes(titleCase(selectedPoiInfo.type))
    : false;

  const isUninitialized = useMemo(() => {
    return selectedPoiInfo.type === BLISTER_TYPE && !selectedPoiInfo.blister;
  }, [selectedPoiInfo.blister, selectedPoiInfo.type]);

  const handleCaptureView = useCallback(
    async (location: ScreenshotLocationType) => {
      await handleSavePoiScreenshot({
        poiId: selectedPoiInfo.id,
        location: location,
        isUpdating,
        setIsUpdating,
        showSnackbar: location === 'screenshots',
      }).then(() => setIsUpdating(false));
    },
    [handleSavePoiScreenshot, isUpdating, selectedPoiInfo.id]
  );

  const screenshotExists = useMemo(() => {
    return (
      selectedPoiInfo?.screenshotPaths &&
      (selectedPoiInfo?.screenshotPaths?.viewer || selectedPoiInfo?.screenshotPaths?.viewpoint)
    );
  }, [selectedPoiInfo?.screenshotPaths]);

  useTriggerPoiLocationUpdateEffect();

  if (!selectedPoiInfo) return <p>Unable to locate selected POI</p>;

  return (
    <Box sx={{ ...boxRootStyle, height: `calc(100vh - ${navbarHeight + 180}px)` }}>
      <MeasureAutoPointOfInterest pointOfInterest={selectedPoiInfo} disableGutters />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.6rem' }}>
          Information
        </Typography>
        {selectedAssemblyId && !isUninitialized && (
          <IconButton onClick={startGeneralFieldsEditing}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Stack>

      <FieldViewer fields={generalFields} />

      {!selectedPoiInfoLoading && (
        <>
          {screenshotExists ? (
            <PoiImage
              viewpointImage={selectedPoiInfo.screenshotPaths?.viewpoint}
              viewerImage={selectedPoiInfo.screenshotPaths?.viewer}
            />
          ) : (
            <Stack
              direction="row"
              gap={1}
              sx={{ backgroundColor: abyssColors.primary[100], p: 1.5 }}
            >
              <InfoOutlinedIcon sx={{ color: abyssColors.primary[500], fontSize: '1.5rem' }} />
              {isAtInsightsPage ? (
                <Typography
                  variant="body2"
                  fontSize="1.2rem"
                  sx={{ color: abyssColors.primary[400] }}
                >
                  Failed to attach the image. Please use the Capture View option to attach it
                  manually.
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  fontSize="1.2rem"
                  sx={{ color: abyssColors.primary[400] }}
                >
                  Failed to attach the image. Please use the{' '}
                  <Typography
                    variant="body2"
                    component="span"
                    fontSize="1.2rem"
                    sx={{
                      color: '#0C8CE9',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleCaptureView('screenshots')}
                  >
                    Capture View
                  </Typography>{' '}
                  option to attach it manually.
                </Typography>
              )}
            </Stack>
          )}
        </>
      )}

      <Divider sx={{ my: 2 }} />

      {isVoi && (
        <>
          <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1.6rem' }}>
            Linked POIs
          </Typography>
          <LinkedPois linkedPois={selectedPoiInfo.linkedPointOfInterestIds} />
        </>
      )}
      <MediaUploadsAndViewer
        selectedPoiInfo={selectedPoiInfo}
        isUpdating={isUpdating}
        setIsUpdating={setIsUpdating}
        handleCaptureView={handleCaptureView}
      />
    </Box>
  );
};
