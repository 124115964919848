import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Stack, Tooltip, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useMemo, useState, Suspense, useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SelectedPoiMenuButton } from './SelectedPoiMenuButton';
import { PoiSuspenseFallback } from './PoiSuspenseFallback';
import { PointOfInterest, useSelectedPoiUpdatedSubscription } from '@/__generated__/graphql';
import { usePointOfInterestQuery } from '@/__generated__/graphql';
import { generatePoiDisplayName } from './utils';
import { abyssColors } from '@/theme/colors';
import { DetailsTab, GeneralTab } from './Tabs';
import { Comments } from '@/components/shared/Comments';

interface TabPanelProps {
  children?: React.ReactNode;
  id: number;
  selectedTabId: number;
}

export function TabContent(props: TabPanelProps) {
  const { children, selectedTabId, id, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTabId !== id}
      id={`simple-tabpanel-${id}`}
      style={{ flex: '1 1 auto' }}
      aria-labelledby={`simple-tab-${id}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {
  selectedPoiId: string;
  closeHandler: () => void;
  disableEdit?: boolean;
  pointOfInterestDetails?: PointOfInterest;
};

const headerStyles = {
  display: 'flex',
  alignItems: 'center',
  p: 1,
  py: 2,
  cursor: 'pointer',
  justifyContent: 'space-between',
};

export const SelectedPoiContent = ({ selectedPoiId, closeHandler, disableEdit }: Props) => {
  const [selectedPoiInfo, setSelectedPoiInfo] = useState<PointOfInterest | undefined>(undefined);
  const {
    data: pointOfInterest,
    loading: selectedPoiInfoLoading,
    refetch,
  } = usePointOfInterestQuery({
    variables: {
      pointOfInterestId: selectedPoiId,
    },
    skip: !selectedPoiId,
  });

  const { data: updatedData } = useSelectedPoiUpdatedSubscription({
    variables: {
      poiID: selectedPoiId,
    },
  });

  useEffect(() => {
    if (pointOfInterest?.pointOfInterest) {
      setSelectedPoiInfo(pointOfInterest.pointOfInterest as PointOfInterest);
    } else {
      setSelectedPoiInfo(undefined);
    }
  }, [pointOfInterest]);

  useEffect(() => {
    if (updatedData?.selectedPointOfInterestUpdated) {
      refetch();
    }
  }, [refetch, updatedData]);

  const tabsData = useMemo(
    () => [
      {
        id: 0,
        label: 'Overview',
        component: selectedPoiInfo && (
          <Suspense fallback={<PoiSuspenseFallback />}>
            <GeneralTab
              selectedPoiInfo={selectedPoiInfo}
              selectedPoiInfoLoading={selectedPoiInfoLoading}
            />
          </Suspense>
        ),
      },
      {
        id: 1,
        label: 'Details',
        component: selectedPoiInfo && (
          <Suspense fallback={<PoiSuspenseFallback />}>
            <DetailsTab disableEdit={disableEdit} selectedPoiInfo={selectedPoiInfo} />
          </Suspense>
        ),
      },
      {
        id: 2,
        label: 'Comments',
        component: (
          <Suspense fallback={<PoiSuspenseFallback />}>
            <Comments referenceId={selectedPoiId} collectionName="pointOfInterests" />
          </Suspense>
        ),
      },
    ],
    [selectedPoiInfo, selectedPoiInfoLoading, disableEdit, selectedPoiId]
  );

  const [selectedTabId, setSelectedTabId] = useState(tabsData[0].id);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabId(newValue);
  };

  if (!selectedPoiInfo) {
    return <PoiSuspenseFallback />;
  }

  const poiName = generatePoiDisplayName({
    name: selectedPoiInfo.name,
    poiId: selectedPoiInfo.poiId,
    poiType: selectedPoiInfo.type,
  });

  return (
    <Stack
      direction="column"
      sx={{ width: '100%', height: '100%', backgroundColor: abyssColors.primary[50] }}
    >
      <Box sx={headerStyles}>
        <Stack direction="row" alignItems="center" onClick={closeHandler}>
          <ArrowBack fontSize="medium" />
          <Tooltip title={poiName} placement="bottom">
            <Typography
              data-testid="poi-name"
              fontSize="1.8rem"
              sx={{
                ml: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '250px',
              }}
            >
              {poiName}
            </Typography>
          </Tooltip>
          {selectedPoiInfo.isVerified && <CheckCircleIcon sx={{ color: 'success.main', ml: 1 }} />}
        </Stack>

        <SelectedPoiMenuButton poiId={selectedPoiInfo.id} />
      </Box>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& button': { textTransform: 'none', fontWeight: 400 },
          '& .Mui-selected': { fontWeight: 500 },
        }}
      >
        <Tabs
          value={selectedTabId}
          onChange={handleChange}
          variant="fullWidth"
          sx={{
            '@media (max-width: 1728px)': {
              minHeight: '36px',
              '& button': {
                padding: 0,
                minHeight: '36px',
                minWidth: 0,
              },
            },
          }}
        >
          {tabsData.map((tab) => (
            <Tab label={tab.label} {...a11yProps(tab.id)} key={tab.id} />
          ))}
        </Tabs>
      </Box>
      {tabsData.map((tab) => (
        <TabContent key={tab.id} selectedTabId={selectedTabId} id={tab.id}>
          {tab.component}
        </TabContent>
      ))}
    </Stack>
  );
};
