import { Box, Button, Stack, Typography, lighten } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { abyssColors, primary } from '@/theme/colors';

type ButtonProps = {
  contained?: boolean;
  compact?: boolean;
};

export const FullScreenPrompt = muiStyled(Box)<{ navbarHeight: number }>(({ navbarHeight }) => ({
  width: '100vw',
  height: `calc(100vh - ${navbarHeight}px)`,
  marginTop: `${navbarHeight}px`,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3.2rem',
}));

export const PromptBackground = muiStyled(Box)<{ background: string }>(({ background }) => ({
  backgroundColor: abyssColors.neutral[50],
  backgroundImage: `url('/assets/${background}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% auto',
  filter: 'blur(5px)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: -2,
}));

export const Overlay = muiStyled(Box)(() => ({
  backgroundColor: 'black',
  opacity: 0.5,
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: -1,
}));

export const PromptDialog = muiStyled(Stack)(() => ({
  background: abyssColors.neutral[50],
  width: '100%',
  maxWidth: '126rem',
  height: '67.2rem',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const GraphicsPanel = muiStyled(Stack)(() => ({
  background:
    'linear-gradient(153deg, #41E2BA 19.19%, #2E9BAE 63.11%, rgba(12, 124, 187, 0.74) 85.38%)',
  width: '50%',
  height: '100%',
  '#logo': {
    maxWidth: '14.8rem',
    marginTop: '4.8rem',
    marginLeft: '4.8rem',
    marginBottom: '2.7rem',
  },
  '#promotionImage1': {
    maxWidth: '35.2rem',
    borderRadius: '1rem',
    overflow: 'hidden',
    marginLeft: '22.5rem',
  },
  '#promotionImage2': {
    maxWidth: '47.8rem',
    borderRadius: '1rem',
    overflow: 'hidden',
    marginLeft: '4rem',
    marginTop: '-5.6rem',
  },
}));

export const DetailsPanel = muiStyled(Stack)(() => ({
  background: abyssColors.neutral[50],
  width: '50%',
  height: '100%',
  padding: '6rem',
  paddingRight: '5rem',
}));

export const Heading = muiStyled(Typography)(() => ({
  color: abyssColors.primary[700],
  fontFamily: `"DM Sans", sans-serif`,
  fontSize: '3.2rem',
  fontWeight: 700,
  lineHeight: '5rem',
  fontFeatureSettings: `'liga' off, 'clig' off`,
  display: 'inline',
}));

export const Highlight = muiStyled(Heading)(() => ({
  color: abyssColors.secondary[600],
}));

export const Paragraph = muiStyled(Typography)(() => ({
  color: abyssColors.primary[400],
  fontFamily: `"DM Sans", sans-serif`,
  fontSize: '1.8rem',
  fontWeight: 400,
  lineHeight: '3rem',
  fontFeatureSettings: `'liga' off, 'clig' off`,
  display: 'inline',
}));

export const SupportButton = muiStyled(Button)(({ contained, compact }: ButtonProps) => ({
  padding: compact ? '0.4rem 1.2rem' : '0.8rem 1.8rem',
  height: compact ? '2.6rem' : '3.4rem',
  borderRadius: '0.4rem',
  fontSize: compact ? '1.2rem' : '1.4rem',
  background: contained ? primary.trueBlue : abyssColors.neutral[50],
  color: contained ? abyssColors.neutral[50] : primary.trueBlue,
  boxShadow: 'none',
  textTransform: 'none',
  width: 'fit-content',
  lineHeight: 'normal',
  marginLeft: '0px !important',
  gap: '0.6rem',
  '&:disabled': {
    background: contained ? lighten(primary.trueBlue, 0.6) : abyssColors.neutral[50],
    color: contained ? abyssColors.neutral[50] : abyssColors.primary[300],
  },
  '&:hover': {
    background: contained ? lighten(primary.trueBlue, 0.3) : abyssColors.neutral[50],
  },
}));

export const Pill = muiStyled(Box)(() => ({
  background: abyssColors.warning[50],
  border: `0.1rem solid ${abyssColors.warning[300]}`,
  borderRadius: '3.1rem',
  display: 'flex',
  alignItems: 'center',
  padding: '0 0.6rem',
  gap: '0.4rem',
  height: '1.8rem',
}));

export const PromptCard = muiStyled(Stack)(() => ({
  background: abyssColors.neutral[50],
  padding: '1.6rem',
  borderRadius: '0.4rem',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  alignItems: 'center',
  gap: '1rem',
}));

export const DetailText = muiStyled(Typography)(() => ({
  color: abyssColors.primary[400],
  fontSize: '1.2rem',
  lineHeight: '1.6rem',
  display: 'inline',
  textAlign: 'center',
}));

export const BoldDetail = muiStyled(DetailText)(() => ({
  fontWeight: 500,
}));
