import {
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FileTypes } from '@/__generated__/graphql';
import { Attachment } from '../../types';
import { AbyssIcon } from '@/assets/icons/AbyssIcon';
import { abyssColors } from '@/theme/colors';
import { useWindowSize } from '../../../../../../../../hooks/useWindowSize';
import { useEffect, useMemo, useState } from 'react';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { uploadFileToUrl } from '../UploadFileToUrl';
import { useUpdatePointOfInterestFileMutation } from '@/__generated__/graphql';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';
import { FileUploadStatus } from '@/__generated__/graphql';
import { theme } from '@/theme/theme';

type Props = {
  selectedAttachment: Attachment;
  selectedFileIndex: number;
  handleRemoveAttachment?: (index: number) => void;
  showRemoveIcon: boolean;
  enableUpload?: boolean;
  onUploadComplete?: (index: number, file: Attachment) => void;
  pointOfInterestId?: string;
};
export const RenderAttachment = ({
  selectedAttachment,
  selectedFileIndex,
  handleRemoveAttachment,
  showRemoveIcon,
  enableUpload = false,
  onUploadComplete,
  pointOfInterestId,
}: Props) => {
  const screenSize = useWindowSize();
  const [updatePoiFile] = useUpdatePointOfInterestFileMutation();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { showSnackBar } = useSnackBarMessage({
    variant: 'filled',
  });
  useEffect(() => {
    const uploadAttachment = async () => {
      if (
        enableUpload &&
        selectedAttachment.isNew &&
        selectedAttachment.url &&
        selectedAttachment.file &&
        selectedAttachment.id &&
        pointOfInterestId
      ) {
        const uploadFile = await uploadFileToUrl(
          selectedAttachment,
          selectedAttachment.url,
          setUploadProgress
        );
        if (uploadFile && selectedAttachment.id) {
          await updatePoiFile({
            variables: {
              input: {
                pointOfInterestId,
                fileId: selectedAttachment.id,
                uploadStatus: FileUploadStatus.Success,
              },
            },

            onError: (error) => {
              showSnackBar(error.message, 'error');
            },
          });
          if (onUploadComplete) onUploadComplete(selectedFileIndex, selectedAttachment);
        }
      }
    };

    uploadAttachment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enableUpload,
    selectedAttachment,
    updatePoiFile,
    onUploadComplete,
    selectedFileIndex,
    pointOfInterestId,
  ]);

  const cardHeight = useMemo(() => {
    const { width } = screenSize;
    if (width && width <= 1440) {
      return '70px';
    }
    return '100px';
  }, [screenSize]);

  const content = () => {
    const isImage =
      selectedAttachment.type.includes('image/') ||
      [FileTypes.Jpeg, FileTypes.Png].includes(selectedAttachment.type as FileTypes);
    const isPdf =
      selectedAttachment.type.includes('application/pdf') ||
      [FileTypes.Pdf].includes(selectedAttachment.type as FileTypes);
    const isVideo =
      selectedAttachment.type.includes('video/mp4') ||
      [FileTypes.Mp4].includes(selectedAttachment.type as FileTypes);
    if (
      selectedAttachment.isNew &&
      enableUpload &&
      FileUploadStatus.Inprogress === selectedAttachment.uploadStatus
    ) {
      return (
        <CardContent
          sx={{
            display: 'flex',
            height: '100px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: abyssColors.primary[100],
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              variant="determinate"
              value={uploadProgress}
              size={60}
              thickness={5}
              sx={{ color: abyssColors.secondary[500] }}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(uploadProgress)}%`}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      );
    } else if (isImage) {
      return (
        <CardMedia
          component="img"
          src={`${selectedAttachment.base64}`}
          alt={selectedAttachment.name}
          sx={{ height: cardHeight, objectFit: 'contain' }}
        />
      );
    } else if (isPdf || isVideo) {
      return (
        <a href={selectedAttachment.url} target="_blank">
          <CardContent
            sx={{
              display: 'flex',
              height: '100px',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: abyssColors.primary[100],
            }}
          >
            {isPdf && <AbyssIcon iconName="pdf-upload" pointer style={{ height: '4rem' }} />}
            {isVideo && (
              <SmartDisplayIcon sx={{ fontSize: 45, color: theme.palette.text.secondary }} />
            )}
          </CardContent>
        </a>
      );
    }
  };

  return (
    <>
      <Card sx={{ position: 'relative', overflow: 'visible' }}>
        {content()}
        {showRemoveIcon && handleRemoveAttachment && (
          <IconButton
            aria-label="delete"
            onClick={() => handleRemoveAttachment(selectedFileIndex)}
            sx={{
              position: 'absolute',
              top: -6,
              right: -6,
              color: 'white',
              backgroundColor: '#FF5D60',
              '&:hover': {
                backgroundColor: 'darkred',
              },
              borderRadius: '50%',
              width: 22,
              height: 22,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Card>

      <Typography
        variant="body2"
        color="textSecondary"
        noWrap
        sx={{
          mt: 1,
          fontFamily: 'Roboto',
          fontSize: '1.2rem',
          fontWeight: '400',
          lineHeight: '14.06px',
          textAlign: 'center',
          color: '#224063',
        }}
      >
        {selectedAttachment.name}
      </Typography>
    </>
  );
};
