import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  Box,
  Divider,
  Stack,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { theme } from '@/theme/theme';
import { RenderAttachment } from './DetailsTab/RenderAttachment';
import {
  PointOfInterest,
  useRemoveAttachmentsFromPoiMutation,
  useUploadAttachmentsToPoiMutation,
  FileUploadStatus,
  PointOfInterestDocument,
} from '@/__generated__/graphql';
import validateFiles from './ValidateFiles';
import { Attachment } from '../types';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';

type Props = {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  pointOfInterest: PointOfInterest;
  attachments: Attachment[];
  dialogTitle: string;
  buttonText: string;
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  setRemediationNote: React.Dispatch<React.SetStateAction<string | undefined | null>>;
};

const convertToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Result of file reading was not a string.'));
      }
    });
    reader.addEventListener('error', () => reject(reader.error));
  });

export const UploadAttachmentsDialog = ({
  openDialog,
  setOpenDialog,
  pointOfInterest,
  attachments,
  dialogTitle,
  buttonText,
  setAttachments,
  setIsUpdating,
  setRemediationNote,
}: Props) => {
  const [uploadedAttachments, setUploadedAttachments] = useState<Attachment[]>([]);
  const [note, setNote] = useState<string>(pointOfInterest.remediationNote || '');
  const [removeAttachments, setRemoveAttachments] = useState<string[]>([]);
  const [uploadAttachmentsToPoi] = useUploadAttachmentsToPoiMutation();
  const [removeAttachmentsFromPoi] = useRemoveAttachmentsFromPoiMutation();
  const [isNoteUpdated, setIsNoteUpdated] = useState<boolean>(false);
  const { showSnackBar } = useSnackBarMessage({
    variant: 'filled',
  });
  useEffect(() => {
    setUploadedAttachments(attachments);
  }, [attachments]);

  const handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
    setIsNoteUpdated(true);
  };

  const handleAddAttachment = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (uploadedAttachments.length + (files?.length || 0) > 5) {
      showSnackBar('You can only upload a maximum of 5 files in total.', 'error');
      return;
    }
    if (files && files.length > 0) {
      const fileArray = [...files];
      const { validFiles, fileErrors } = validateFiles(fileArray);

      if (fileErrors.length > 0) {
        showSnackBar('You can only upload a maximum of 5 files in total.', 'error');
        fileErrors.map((error) => showSnackBar(error, 'error'));
        return;
      }

      const duplicateFiles = validFiles.filter((file) =>
        uploadedAttachments.some((att) => att.name === file.name.replace(/[^\w.-]/g, '_'))
      );

      if (duplicateFiles.length > 0) {
        showSnackBar(
          `Duplicate file names found: ${duplicateFiles.map((file) => file.name).join(', ')}`,
          'error'
        );

        return;
      }

      const attachmentsPayload: Attachment[] = await Promise.all(
        validFiles.map(async (file, index) => {
          const isImageOrVideo = file.type.startsWith('image/');
          const base64 = isImageOrVideo ? await convertToBase64(file) : '';
          return {
            uuid: `file-${Date.now()}-${index}`,
            name: file.name,
            type: file.type,
            base64: base64,
            isNew: true,
            size: file.size,
            file,
            uploadStatus: FileUploadStatus.Inprogress || 'INPROGRESS',
          };
        })
      );
      setUploadedAttachments((previousAttachments) => [
        ...previousAttachments,
        ...attachmentsPayload,
      ]);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    const newAttachments = uploadedAttachments.filter((_, subIndex) => subIndex !== index);
    if (
      uploadedAttachments[index] &&
      !uploadedAttachments[index].isNew &&
      uploadedAttachments[index].id
    ) {
      setRemoveAttachments((previousRemovedAttachments) => [
        ...previousRemovedAttachments,
        uploadedAttachments[index].id || '',
      ]);
    }
    setUploadedAttachments(newAttachments);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setRemoveAttachments([]);
    setNote(pointOfInterest.remediationNote || '');
    setUploadedAttachments(attachments);
  };

  const handleRemoveNote = () => {
    setNote('');
    setIsNoteUpdated(true);
  };

  const handleUpdate = async () => {
    const attachmentsToBeUploaded = uploadedAttachments.filter((file) => file.isNew);
    setOpenDialog(false);
    setRemediationNote(note);
    if (removeAttachments.length > 0) {
      setIsUpdating(true);

      setAttachments(() =>
        attachments.filter(
          (attachment) => attachment.id && !removeAttachments.includes(attachment.id)
        )
      );
      await removeAttachmentsFromPoi({
        variables: {
          input: {
            pointOfInterestId: pointOfInterest.id,
            fileIds: removeAttachments,
          },
        },
        refetchQueries: [PointOfInterestDocument],
        onCompleted: () => {
          setIsUpdating(false);
          showSnackBar('Attachments have been successfully updated', 'success');
        },
        onError: (error) => {
          setIsUpdating(false);
          showSnackBar(error.message, 'error');
        },
      });
    }
    if (attachmentsToBeUploaded.length > 0 || isNoteUpdated) {
      setAttachments((previousAttachments) => [...previousAttachments, ...attachmentsToBeUploaded]);
      setIsUpdating(true);

      try {
        const { data } = await uploadAttachmentsToPoi({
          variables: {
            input: {
              pointOfInterestId: pointOfInterest.id,
              remediationNote: note,
              files: attachmentsToBeUploaded.map(({ uuid, name, type, size }) => ({
                uuid: uuid || '',
                name,
                size: size || 0,
                mimeType: type,
              })),
              lastModifiedFor: 'Media Upload',
            },
          },
          onError: (error) => {
            setIsUpdating(false);
            showSnackBar(error.message, 'error');
            return;
          },
        });
        if (data?.uploadAttachmentsToPoi && data?.uploadAttachmentsToPoi?.length > 0) {
          const updatedAttachments = uploadedAttachments.map((attachment) => {
            const uploadedFile = data?.uploadAttachmentsToPoi?.find(
              (uploaded) => uploaded.uuid === attachment.uuid
            );
            if (uploadedFile) {
              return {
                ...attachment,
                url: uploadedFile.url,
                resourcePath: uploadedFile.resourcePath,
                id: uploadedFile.id,
              };
            }
            return attachment;
          });
          setAttachments(updatedAttachments);
        }
        if (isNoteUpdated && attachmentsToBeUploaded.length === 0) {
          setIsUpdating(false);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        setOpenDialog(false);
      }
    }

    setRemoveAttachments([]);
    setUploadedAttachments([]);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ color: theme.palette.primary.dark }}>
            Write your note here
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, marginLeft: 1 }}>
            (max 200 characters allowed)
          </Typography>
        </Box>
        <TextField
          margin="dense"
          id="note"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={note}
          onChange={handleNoteChange}
          inputProps={{ maxLength: 200 }}
        />
        <Box sx={{ pt: 2 }}>
          <Typography variant="caption" sx={{ color: theme.palette.primary.dark }}>
            Attachments{'  '}
          </Typography>
          <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
            (max 5 uploads)
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ my: 1 }}>
          <label htmlFor="file-upload" style={{ marginLeft: '15px' }}>
            <input
              accept="image/jpeg,image/png,application/pdf,video/mp4"
              style={{ display: 'none' }}
              id="file-upload"
              multiple
              type="file"
              onChange={handleAddAttachment}
            />
            <Box
              sx={{
                border: '1px dashed #B8CADD',
                p: '25px',
                borderRadius: 2,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '14px',
              }}
            >
              <ArrowUpwardIcon style={{ color: theme.palette.text.secondary }} />
              <Typography
                variant="caption"
                style={{ color: theme.palette.text.secondary, marginTop: 8 }}
              >
                Upload
              </Typography>
            </Box>
          </label>
          {uploadedAttachments.map((attachment, index) => (
            <Grid item key={attachment.id || attachment.name} xs={2} md={2} sm={3}>
              <RenderAttachment
                selectedAttachment={attachment}
                selectedFileIndex={index}
                handleRemoveAttachment={handleRemoveAttachment}
                showRemoveIcon
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between', px: 2 }}>
        <Stack direction="row" justifyContent="flex-start" spacing={2} sx={{ mx: '12px' }}>
          <Button
            onClick={handleRemoveNote}
            color="primary"
            variant="text"
            startIcon={<DeleteOutlinedIcon />}
            sx={{ borderRadius: 2, color: theme.palette.primary.dark, ml: 0 }}
          >
            Remove Note
          </Button>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            sx={{ borderRadius: 2 }}
            id="cancel-poi-attachment"
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            color="primary"
            id="update-poi-attachment"
            variant="contained"
            sx={{ borderRadius: 2, background: theme.palette.primary.dark, marginRight: '12px' }}
          >
            {buttonText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default UploadAttachmentsDialog;
