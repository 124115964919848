import { Box, Divider, Stack, Typography } from '@mui/material';
import { Maybe } from '@/__generated__/graphql';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as poiState from '../../../../pointOfInterest/state';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type Props = {
  linkedPois: Maybe<string[]> | undefined;
};
export const LinkedPois = ({ linkedPois }: Props) => {
  const poiFilteredByCheckbox = useRecoilValue(poiState.poiFilteredByCheckbox);
  const setSelectedPointOfInterestId = useSetRecoilState(poiState.selectedPointOfInterestId);

  if (!linkedPois || linkedPois?.length < 1) {
    return <Box>No linked POIs</Box>;
  }
  const pois = poiFilteredByCheckbox.filter((poi) => linkedPois.includes(poi.id));

  return (
    <Stack>
      {pois.map((poi) => (
        <>
          <Box
            key={poi.id}
            onClick={() => setSelectedPointOfInterestId(poi.id)}
            sx={{
              padding: '1%',
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
          >
            <Typography fontSize={14}>
              {poi.name} - {poi.poiId}
            </Typography>
            <ChevronRightIcon />
          </Box>
          <Divider sx={{ mb: 1 }} />
        </>
      ))}
    </Stack>
  );
};
